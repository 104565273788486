import './Template.scss'
import Layout from '../../Layout/Layout'
import Hero from '../../prebuild-sections/Hero/Hero'
import Separator from '../../prebuild-sections/Separator/Separator'
import Headline from '../../prebuild-sections/Headline/Headline'
import { useSelector } from 'react-redux'
import CustomContainer from '../../prebuild-sections/CustomContainer/CustomContainer'
import { getUUID } from '../../../utility'
import HexItem from './HexItem/HexItem'
import GetToKnow from '../../prebuild-sections/GetToKnow/GetToKnow'
import anchor from '../../../assets/image/anchor.png'
import { useEffect, useState } from 'react'
import MetaTags from '../../prebuild-sections/MetaTags/MetaTags'
import ShowIfVisible from '../../animations/ShowIfVisible'
import slideUp from '../../animations/slideUp'
import slideLeftToRight from '../../animations/slideLeftToRight'

const BranchTemplate = ({
  translationKeyword,
  hero,
  svgs,
  trustedBrandImages,
  bulletpointImages,
}) => {
  const translation = useSelector((s) => s.translation)
  const [activeBackgroundImageIndex, setActiveBackgroundImageIndex] = useState(0)

  useEffect(() => {
    const galleryInterval = setInterval(() => {
      if (activeBackgroundImageIndex + 1 > bulletpointImages.length - 1) {
        setActiveBackgroundImageIndex(0)
      } else {
        setActiveBackgroundImageIndex(activeBackgroundImageIndex + 1)
      }
    }, 2500)
    return () => clearInterval(galleryInterval)
  }, [activeBackgroundImageIndex, bulletpointImages])

  const getSubheadline2CoreText = () => {
    const keywords = translation.branches[translationKeyword].keywords
    return `${keywords.slice(0, keywords.length - 1).join(', ')}${
      translation.branchesGeneric.subheadline2LastGlue
    }${keywords[keywords.length - 1]}`
  }

  return (
    <Layout>
      <MetaTags
        title={translation.branches[translationKeyword].headline2}
        description={translation.branches[translationKeyword].headline1}
        image={hero}
      />
      {translation && (
        <div id="branch-template">
          <Hero img={hero} />
          <CustomContainer>
            <div className="keywords">
              {translation.branches[translationKeyword].keywords.map((keyword) => (
                <div className="keyword" key={getUUID()}>
                  {keyword}
                </div>
              ))}
            </div>
            <Headline>{translation.branches[translationKeyword].headline1}</Headline>
            <ShowIfVisible animation={slideUp}>
              <div className="hex-items">
                {svgs?.map((svg, i) => (
                  <div className="hex-item-wrapper" key={getUUID()}>
                    <HexItem svg={svg} isOdd={i % 2 === 1} />
                  </div>
                ))}
              </div>
            </ShowIfVisible>
            <Separator />
            <div className="trusted-by">
              <div className="label">{translation.branchesGeneric.trustedBy}</div>
              <div className="brands">
                {trustedBrandImages.map((image) => (
                  <div className="logo" key={getUUID()}>
                    <img src={image} alt="" />
                  </div>
                ))}
              </div>
            </div>
            <Separator />
            <div className="anchor-icon">
              <img src={anchor} alt="anchor" />
            </div>
            <Headline>_{translation.branches[translationKeyword].headline2}</Headline>
            <div className="subheadline">
              {translation.branchesGeneric.subheadline2Prefix}
              {getSubheadline2CoreText()}
              {translation.branchesGeneric.subheadline2Suffix}
            </div>
            <div
              className="bulletpoints"
              style={{
                backgroundImage: `url(${bulletpointImages[activeBackgroundImageIndex]})`,
              }}
            >
              <ShowIfVisible animation={slideLeftToRight}>
                {translation.branches[translationKeyword].bulletpoints.map((bulletpoint) => (
                  <div className="bulletpoint" key={getUUID()}>
                    <div className="text">{bulletpoint}</div>
                  </div>
                ))}
              </ShowIfVisible>
              <div className="bulletpoint-image-mobile">
                <img src={bulletpointImages[activeBackgroundImageIndex]} alt="" />
              </div>
              <div className="go-to-anchorstrategy-cta-wrapper">
                <a
                  href="/anchorstrategy"
                  target="_blank"
                  rel="noreferrer"
                  className="go-to-anchorstrategy"
                >
                  {translation.branchesGeneric.goToAnchorstrategyCTA}
                </a>
              </div>
            </div>
            <Separator />
            <Headline>
              <div className="headline-content">{translation.branchesGeneric.headline3}</div>
            </Headline>
            <div className="subheadline">{translation.branchesGeneric.subheadline3}</div>
            <div className="get-to-know">
              <GetToKnow />
            </div>
          </CustomContainer>
        </div>
      )}
    </Layout>
  )
}

export default BranchTemplate
