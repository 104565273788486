import { useState } from 'react'
import { useSelector } from 'react-redux'
import './Header.scss'
import logo from '../../../assets/image/header/logo.png'
import toggleOpenIcon from '../../../assets/image/header/toggleOpen.png'
import toggleCloseIcon from '../../../assets/image/header/toggleClose.png'
import { getUUID } from '../../../utility'
import linkedin from '../../../assets/image/contact/linkedin.png'
import youtube from '../../../assets/image/contact/youtube.png'
import instagram from '../../../assets/image/contact/instagram.png'
import twitter from '../../../assets/image/contact/twitter.png'
import vimeo from '../../../assets/image/contact/vimeo.png'

const Header = ({ noLogo }) => {
  const translation = useSelector((s) => s.translation)
  const [navigationVisible, setNavigationVisible] = useState(false)

  const toggle = () => setNavigationVisible(!navigationVisible)

  return (
    <div id="header" className={navigationVisible ? 'fixed' : ''}>
      <div className="logo">
        {!noLogo && (
          <a href="/">
            <img src={logo} alt={translation.project.name} />
          </a>
        )}
      </div>
      <div className="toggle">
        {!navigationVisible && (
          <img src={toggleOpenIcon} onClick={toggle} alt={translation.project.name} />
        )}
        {navigationVisible && (
          <img src={toggleCloseIcon} onClick={toggle} alt={translation.project.name} />
        )}
      </div>
      {navigationVisible && (
        <div className="navigation">
          {translation.header.nav.items.map((item) => {
            const smallTextItems = ['impressum', 'datenschutz']
            return (
              <div
                className={smallTextItems.includes(item.link) ? 'item small' : 'item'}
                key={getUUID()}
              >
                <a href={item.link}>{item.name}</a>
              </div>
            )
          })}
          <div className="social-icons">
            <a
              href="https://www.linkedin.com/company/black-flagship/"
              target="_blank"
              rel="noreferrer"
            >
              <img src={linkedin} alt="linkedin" />
            </a>
            <a
              href="https://www.youtube.com/channel/UCFD49ze_UGVue6unGWhYKTg"
              target="_blank"
              rel="noreferrer"
            >
              <img src={youtube} alt="youtube" />
            </a>
            <a href="https://www.instagram.com/black_flagship/" target="_blank" rel="noreferrer">
              <img src={instagram} alt="instagram" />
            </a>
            <a href="https://twitter.com/black_flagship" target="_blank" rel="noreferrer">
              <img src={twitter} alt="twitter" />
            </a>
            <a href="https://vimeo.com/blackflagship" target="_blank" rel="noreferrer">
              <img src={vimeo} alt="vimeo" />
            </a>
          </div>
        </div>
      )}
    </div>
  )
}

export default Header
