import Template from './Template/Template'
import hero from '../../assets/image/branches/luxus/hero.png'

// trausted brand images
import boconcpet_logo from '../../assets/image/branches/luxus/logos/boconcpet_logo.png'
import mont_blanc_logo from '../../assets/image/branches/luxus/logos/mont_blanc_logo.png'
import sennheiser_logo from '../../assets/image/branches/luxus/logos/sennheiser_logo.png'
import shitshop_logo from '../../assets/image/branches/luxus/logos/shit-shop_logo.png'
import societyshop_logo from '../../assets/image/branches/luxus/logos/societyshop_logo.png'
import teufel_logo from '../../assets/image/branches/luxus/logos/teufel_logo.png'
import webergrill_logo from '../../assets/image/branches/luxus/logos/webergrill_logo.png'

// bulletpoint images
import blackflagship_markenagentur_0005_luxus6 from '../../assets/image/branches/luxus/gallery/blackflagship_markenagentur_0005_luxus6.png'
import blackflagship_markenagentur_0006_luxus_7 from '../../assets/image/branches/luxus/gallery/blackflagship_markenagentur_0006_luxus_7.png'
import blackflagship_markenagentur_0007_luxus_5 from '../../assets/image/branches/luxus/gallery/blackflagship_markenagentur_0007_luxus_5.png'
import blackflagship_markenagentur_0008_luxus_4 from '../../assets/image/branches/luxus/gallery/blackflagship_markenagentur_0008_luxus_4.png'
import blackflagship_markenagentur_0009_luxus_3 from '../../assets/image/branches/luxus/gallery/blackflagship_markenagentur_0009_luxus_3.png'
import blackflagship_markenagentur_0010_luxus_2 from '../../assets/image/branches/luxus/gallery/blackflagship_markenagentur_0010_luxus_2.png'
import blackflagship_markenagentur_0011_luxus_1 from '../../assets/image/branches/luxus/gallery/blackflagship_markenagentur_0011_luxus_1.png'

import hexSVG1 from '../../assets/image/branches/luxus/svgs/Luxus_01.svg'
import hexSVG2 from '../../assets/image/branches/luxus/svgs/Luxus_02.svg'
import hexSVG3 from '../../assets/image/branches/luxus/svgs/Luxus_03.svg'
import hexSVG4 from '../../assets/image/branches/luxus/svgs/Luxus_04.svg'
import hexSVG5 from '../../assets/image/branches/luxus/svgs/Luxus_05.svg'

const Luxus = () => {
  const trustedBrandImages = [
    boconcpet_logo,
    mont_blanc_logo,
    sennheiser_logo,
    shitshop_logo,
    societyshop_logo,
    teufel_logo,
    webergrill_logo,
  ]
  const bulletpointImages = [
    blackflagship_markenagentur_0005_luxus6,
    blackflagship_markenagentur_0006_luxus_7,
    blackflagship_markenagentur_0007_luxus_5,
    blackflagship_markenagentur_0008_luxus_4,
    blackflagship_markenagentur_0009_luxus_3,
    blackflagship_markenagentur_0010_luxus_2,
    blackflagship_markenagentur_0011_luxus_1,
  ]

  const hexSvgs = [hexSVG1, hexSVG2, hexSVG3, hexSVG4, hexSVG5]

  return (
    <Template
      translationKeyword={'luxus'}
      hero={hero}
      svgs={hexSvgs}
      trustedBrandImages={trustedBrandImages}
      bulletpointImages={bulletpointImages}
    />
  )
}

export default Luxus
