import Template from './Template/Template'
import hero from '../../assets/image/branches/jewelry/hero.png'

// trausted brand images
import casio_logo from '../../assets/image/branches/jewelry/logos/casio_logo.png'
import chopard_logo from '../../assets/image/branches/jewelry/logos/chopard_logo.png'
import degussawatches_logo from '../../assets/image/branches/jewelry/logos/degussawatches_logo.png'
import hublot_logo from '../../assets/image/branches/jewelry/logos/hublot_logo.png'
import mont_blanc_logo from '../../assets/image/branches/jewelry/logos/mont_blanc_logo.png'
import viakoro_logo from '../../assets/image/branches/jewelry/logos/viakoro_logo.png'

// bulletpoint images

import blackflagship_markenagentur_0000_Schmuck_5 from '../../assets/image/branches/jewelry/gallery/blackflagship_markenagentur_0000_Schmuck_5.png'
import blackflagship_markenagentur_0000_Schmuck_6 from '../../assets/image/branches/jewelry/gallery/blackflagship_markenagentur_0000_Schmuck_6.png'
import blackflagship_markenagentur_0000_Schmuck_7 from '../../assets/image/branches/jewelry/gallery/blackflagship_markenagentur_0000_Schmuck_7.png'
import blackflagship_markenagentur_0000_Schmuck_8 from '../../assets/image/branches/jewelry/gallery/blackflagship_markenagentur_0000_Schmuck_8.png'
import blackflagship_markenagentur_0000_Schmuck_9 from '../../assets/image/branches/jewelry/gallery/blackflagship_markenagentur_0000_Schmuck_9.png'
import blackflagship_markenagentur_0000_Schmuck_10 from '../../assets/image/branches/jewelry/gallery/blackflagship_markenagentur_0000_Schmuck_10.png'
import blackflagship_markenagentur_0000_Schmuck_11 from '../../assets/image/branches/jewelry/gallery/blackflagship_markenagentur_0000_Schmuck_11.png'
import blackflagship_markenagentur_0001_Schmuck_4 from '../../assets/image/branches/jewelry/gallery/blackflagship_markenagentur_0001_Schmuck_4.png'
import blackflagship_markenagentur_0002_Schmuck_3 from '../../assets/image/branches/jewelry/gallery/blackflagship_markenagentur_0002_Schmuck_3.png'
import blackflagship_markenagentur_0003_Schmuck_2 from '../../assets/image/branches/jewelry/gallery/blackflagship_markenagentur_0003_Schmuck_2.png'
import blackflagship_markenagentur_0004_Schmuck_1 from '../../assets/image/branches/jewelry/gallery/blackflagship_markenagentur_0004_Schmuck_1.png'

import hexSVG1 from '../../assets/image/branches/jewelry/svgs/Schmuck_1.svg'
import hexSVG2 from '../../assets/image/branches/jewelry/svgs/Schmuck_2.svg'
import hexSVG3 from '../../assets/image/branches/jewelry/svgs/Schmuck_3.svg'
import hexSVG4 from '../../assets/image/branches/jewelry/svgs/Schmuck_4.svg'
import hexSVG5 from '../../assets/image/branches/jewelry/svgs/Schmuck_5.svg'

const Jewelry = () => {
  const trustedBrandImages = [
    casio_logo,
    chopard_logo,
    degussawatches_logo,
    hublot_logo,
    mont_blanc_logo,
    viakoro_logo,
  ]
  const bulletpointImages = [
    blackflagship_markenagentur_0000_Schmuck_5,
    blackflagship_markenagentur_0000_Schmuck_6,
    blackflagship_markenagentur_0000_Schmuck_7,
    blackflagship_markenagentur_0000_Schmuck_8,
    blackflagship_markenagentur_0000_Schmuck_9,
    blackflagship_markenagentur_0000_Schmuck_10,
    blackflagship_markenagentur_0000_Schmuck_11,
    blackflagship_markenagentur_0001_Schmuck_4,
    blackflagship_markenagentur_0002_Schmuck_3,
    blackflagship_markenagentur_0003_Schmuck_2,
    blackflagship_markenagentur_0004_Schmuck_1,
  ]

  const hexSvgs = [hexSVG1, hexSVG2, hexSVG3, hexSVG4, hexSVG5]

  return (
    <Template
      translationKeyword={'jewelry'}
      hero={hero}
      svgs={hexSvgs}
      trustedBrandImages={trustedBrandImages}
      bulletpointImages={bulletpointImages}
    />
  )
}

export default Jewelry
