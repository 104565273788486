import './Anchorworkshop.scss'
import Layout from '../Layout/Layout'
import heroImage from '../../assets/image/anchorworkshop/hero.png'
import Hero from '../prebuild-sections/Hero/Hero'
import Separator from '../prebuild-sections/Separator/Separator'
import Headline from '../prebuild-sections/Headline/Headline'
import { useSelector } from 'react-redux'
import CustomContainer from '../prebuild-sections/CustomContainer/CustomContainer'
import { getUUID } from '../../utility'
import Bulletpoint from './Bulletpoint/Bulletpoint'
import HexSection from './HexSection/HexSection'
import GetToKnow from '../prebuild-sections/GetToKnow/GetToKnow'
import MetaTags from '../prebuild-sections/MetaTags/MetaTags'
import HexItem from '../Anchorstrategy/HexItem/HexItem'
import img1 from '../../assets/image/anchorworkshop/Element 80.svg'
import img2 from '../../assets/image/anchorworkshop/Element 79.svg'
import img3 from '../../assets/image/anchorworkshop/Element 78.svg'
import img4 from '../../assets/image/anchorworkshop/Element 77.svg'
import ShowIfVisible from '../animations/ShowIfVisible'
import slideUp from '../animations/slideUp'

const Anchorworkshop = () => {
  const translation = useSelector((s) => s.translation)

  return (
    <Layout>
      <MetaTags
        title={translation.anchorworkshop.title}
        description={translation.anchorworkshop.headline1}
        image={heroImage}
      />
      <div id="anchorworkshop">
        <Hero img={heroImage} />
        <CustomContainer>
          <Headline>{translation.anchorworkshop.headline1}</Headline>
          <ShowIfVisible animation={slideUp}>
            <div className="hex-items">
              <HexItem svg={img1} />
              <HexItem svg={img2} isOdd />
              <HexItem svg={img3} />
              <HexItem svg={img4} isOdd />
            </div>
          </ShowIfVisible>
          <div className="book-meeting-cta-wrapper">
            <a
              href="https://go.oncehub.com/ANCHORWORKSHOP"
              target="_blank"
              rel="noreferrer"
              className="book-meeting-cta"
            >
              {translation.anchorworkshop.bookMeetingCTA}
            </a>
          </div>
          <Separator />
          <Headline>
            <div className="headline-content">{translation.anchorworkshop.headline2}</div>
          </Headline>
          <div className="bulletpoints">
            {translation.anchorworkshop.bulletpoints.map((bulletpoint, i) => (
              <Bulletpoint bulletpoint={bulletpoint} index={i} key={getUUID()} />
            ))}
          </div>
          <Separator />
          <Headline>
            <div className="headline-content">{translation.anchorworkshop.headline3}</div>
          </Headline>
          <div className="hex-section">
            <HexSection />
          </div>
          <Separator />
          <Headline>
            <div className="headline-content">{translation.anchorworkshop.headline4}</div>
          </Headline>
          <div className="get-to-know">
            <GetToKnow />
          </div>
        </CustomContainer>
      </div>
    </Layout>
  )
}

export default Anchorworkshop
