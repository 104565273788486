import './Contact.scss'
import Layout from '../Layout/Layout'
import heroImage from '../../assets/image/contact/hero.png'
import Hero from '../prebuild-sections/Hero/Hero'
import wheelIcon from '../../assets/image/wheel.png'
import { useSelector } from 'react-redux'
import CustomContainer from '../prebuild-sections/CustomContainer/CustomContainer'
import Headline from '../prebuild-sections/Headline/Headline'
import mailIcon from '../../assets/image/contact/mail.png'
import videoIcon from '../../assets/image/contact/video.png'
import phoneIcon from '../../assets/image/contact/phone.png'
import shipImage from '../../assets/image/contact/ship.png'
import logoIcon from '../../assets/image/contact/logo.png'
import linkedin from '../../assets/image/contact/linkedin.png'
import youtube from '../../assets/image/contact/youtube.png'
import instagram from '../../assets/image/contact/instagram.png'
import twitter from '../../assets/image/contact/twitter.png'
import vimeo from '../../assets/image/contact/vimeo.png'
import { getUUID } from '../../utility'
import Separator from '../prebuild-sections/Separator/Separator'
import MetaTags from '../prebuild-sections/MetaTags/MetaTags'

const Contact = () => {
  const translation = useSelector((s) => s.translation)
  const bulletpoints = [
    {
      img: mailIcon,
      text: translation.contact.bulletpoints.mail,
      href: 'mailto:office@blackflagship.com',
    },
    {
      img: videoIcon,
      text: translation.contact.bulletpoints.video,
      href: 'https://go.oncehub.com/MeetingEG',
    },
    {
      img: phoneIcon,
      text: translation.contact.bulletpoints.phone,
      href: 'https://go.oncehub.com/EmanuelGrigorescu',
    },
  ]

  return (
    <Layout>
      <MetaTags
        title={translation.contact.title}
        description={translation.contact.headline1}
        image={heroImage}
      />
      <div id="contact">
        <Hero img={heroImage} />
        <CustomContainer>
          <div className="wheel-wrapper">
            <img src={wheelIcon} alt="wheel" />
          </div>
          <Headline>
            <div>{translation.contact.headline1}</div>
            <div>{translation.contact.headline2}</div>
          </Headline>
          <div className="flex">
            <div className="bulletpoint-list">
              {bulletpoints.map((b) => (
                <div className="bulletpoint" key={getUUID()}>
                  <a href={b.href} target="_blank" rel="noreferrer">
                    <div className="image">
                      <img src={b.img} alt={b.text} />
                    </div>
                  </a>
                  <a href={b.href} target="_blank" rel="noreferrer">
                    <div className="text">{b.text}</div>
                  </a>
                </div>
              ))}
            </div>
            <div className="ship">
              <img src={shipImage} alt="ship" />
            </div>
          </div>
          <div className="logo-wrapper">
            <img src={logoIcon} alt="logo" />
          </div>
          <Separator />
          <div className="social">
            <a
              href="https://www.linkedin.com/company/black-flagship/"
              target="_blank"
              rel="noreferrer"
            >
              <img src={linkedin} alt="linkedin" />
            </a>
            <a
              href="https://www.youtube.com/channel/UCFD49ze_UGVue6unGWhYKTg"
              target="_blank"
              rel="noreferrer"
            >
              <img src={youtube} alt="youtube" />
            </a>
            <a href="https://www.instagram.com/black_flagship/" target="_blank" rel="noreferrer">
              <img src={instagram} alt="instagram" />
            </a>
            <a href="https://twitter.com/black_flagship" target="_blank" rel="noreferrer">
              <img src={twitter} alt="twitter" />
            </a>
            <a href="https://vimeo.com/blackflagship" target="_blank" rel="noreferrer">
              <img src={vimeo} alt="vimeo" />
            </a>
          </div>
          <Separator />
        </CustomContainer>
      </div>
    </Layout>
  )
}

export default Contact
