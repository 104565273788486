import toggleup from "../../../assets/image/who-we-are/toggle-up.png";
import toggledown from "../../../assets/image/who-we-are/toggle-down.png";
import "./Slider.scss";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

const Slider = () => {
  const translation = useSelector((s) => s.translation);
  const [activeIndex, setActiveIndex] = useState(0);
  const [activeSlide, setActiveSlider] = useState(null);

  const move = (indexOffset) => () => {
    const newIndex = activeIndex + indexOffset;
    const slides = translation.whoWeAre.slides;
    if (newIndex === -1) {
      setActiveIndex(slides.length - 1);
    } else if (newIndex >= slides.length) {
      setActiveIndex(0);
    } else {
      setActiveIndex(newIndex);
    }
  };

  useEffect(() => {
    setActiveSlider(translation.whoWeAre.slides[activeIndex]);
  }, [activeIndex, translation]);

  if (activeSlide === null) return <></>;
  return (
    <div className="who-we-are-slider">
      <div className="toggle" onClick={move(-1)}>
        <img src={toggleup} alt="" />
      </div>
      <div className="content">
        <div className="person">{activeSlide.person}</div>
        <div className="position">{activeSlide.position}</div>
        <div className="text">{activeSlide.text}</div>
      </div>
      <div className="toggle" onClick={move(1)}>
        <img src={toggledown} alt="" />
      </div>
    </div>
  );
};

export default Slider;
