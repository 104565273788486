const Text = () => {
  return (
    <div className="text">
      <div class="container">
        <div class="headline">DATENSCHUTZ</div>
        <div class="text">
          <div>
            Mit nachfolgenden Informationen möchten wir Ihnen einen Überblick
            verschaffen, wie wir mit Ihren Daten umgehen, die wir im
            Zusammenhang mit dem Besuch unserer Internetseite erheben. Zudem
            möchten wir Sie über Ihre Datenschutzrechte informieren.
          </div>
        </div>
        <div class="section">
          <div class="subheadline">
            1. Verantwortlicher, Art. 4 Ziffer 7 DSGVO
          </div>
          <div class="text">
            <div>
              Der für die Verarbeitung Ihrer Daten Verantwortliche im Sinne von
              Art. 4 Ziffer 7 Datenschutz – Grundverordnung (DSGVO) ist die
            </div>
            <div class="spacer"></div>
            <div>Blackflagship Markenagentur GmbH</div>
            <div>Alt-Tempelhof 37</div>
            <div>12103 Berlin</div>
          </div>
        </div>
        <div class="section">
          <div class="subheadline">2. Was sind „Personenbezogene Daten”?</div>
          <div class="text">
            Personenbezogene Daten sind Informationen zu Ihrer Person, die
            Rückschlüsse auf Ihre Identität zulassen, z.B. Ihr Name, Ihre
            Adresse oder Ihre Telefonnummer. Informationen, die keine
            Rückschlüsse auf eine bestimmte oder bestimmbare Person zulassen,
            fallen nicht darunter.
          </div>
        </div>
        <div class="section">
          <div class="subheadline">
            3. Umfang der Datenerhebung, -verarbeitung und -nutzung
          </div>
          <div class="text">
            <div class="boldfont">a) Besuch unserer Webseite</div>
            <div>
              Beim rein informatorischen Besuch unserer Internetseite, also wenn
              Sie uns nicht anderweitig Informationen übermitteln, erheben wir
              nur die personenbezogenen Daten, die Ihr Browser an unseren Server
              übermittelt. Wenn Sie unsere Website betrachten möchten, er- heben
              wir die folgenden Daten, die für uns technisch erforderlich sind,
              um Ihnen unsere Website anzuzeigen und die Stabilität und
              Sicherheit zu gewährleisten:
            </div>
            <div class="spacer"></div>
            <ul class="list">
              <li>Ihre IP-Adresse</li>
              <li>der Name Ihres Internetserviceproviders</li>
              <li>die Website, von der aus Sie uns besuchen</li>
              <li>die Webseiten, die Sie bei uns aufrufen</li>
            </ul>
            <div class="spacer"></div>
            <div class="boldfont">b) Cookies</div>
            <div>
              Unsere Internetseiten verwenden teilweise so genannte Cookies.
              Diese dienen dazu, unser Angebot nutzerfreundlicher, effektiver
              und sicherer zu machen. Cookies sind kleine Textdateien, die auf
              Ihrem Rechner abgelegt werden, und die Ihr Browser speichert. Die
              meisten der von uns verwendeten Cookies sind so genannte
              „Session-Cookies“. Sie werden nach Ende Ihres Besuchs automatisch
              gelöscht. Cookies richten auf Ihrem Rechner keinen Schaden an und
              enthalten keine Viren.
            </div>
            <div class="spacer"></div>
            <div>
              Um den Einsatz von Cookies zu unterbinden, können Sie Ihre
              Browser-Einstellung entsprechend Ihren Wünschen konfigurieren und
              z. B. die Annahme von Third-Party-Cookies oder allen Cookies
              ablehnen. Wir weisen Sie darauf hin, dass Sie eventuell nicht alle
              Funktionen dieser Website nutzen können.
            </div>
            <div class="spacer"></div>
            <div class="boldfont">c) Google Maps</div>
            <div>
              Auf unserer Internetseite nutzen wir das Angebot von Google Maps,
              einem weiteren Dienst von Google. Mit Google Maps können wir Ihnen
              interaktive Karten direkt in der Website anzeigen und ermöglichen
              Ihnen somit die komfortable Nutzung der Karten-Funktion.
            </div>
            <div class="spacer"></div>
            <div>
              Für die Nutzung der Kartenfunktion ist die Verarbeitung Ihrer
              IP-Adresse technisch notwendig, um Ihrem Browser die
              entsprechenden Informationen zur Verfügung zu stellen. Ihre
              IP-Adresse wird daher an Google übermittelt, wenn Sie eine Seite
              mit einer Google – Map aufrufen.
            </div>
            <div class="spacer"></div>
            <div>
              Weitere Informationen zu Zweck und Umfang der Datenerhebung und
              ihrer Rechte erhalten Sie in den Datenschutzerklärungen des
              Anbieters: http://www.google.de/intl/de/policies/privacy. Dort
              finden Sie auch Hinweise, wie Sie die Erfassung und Verarbeitung
              von Daten durch entsprechende Einstellungen verhindern können.
            </div>
            <div class="spacer"></div>
            <div class="boldfont">d) Nutzung von Vimeo-Plugins</div>
            <div>
              Wir nutzen für die Einbindung von Videos u.a. den Anbieter Vimeo.
              Vimeo wird betrieben von Vimeo, LLC mit Hauptsitz in 555 West 18th
              Street, New York, New York 10011. Auf einigen unserer
              Internetseiten verwenden wir Plugins von Vimeo. Wenn Sie eine mit
              einem solchen Plugin versehene Internetseite unserer
              Internetpräsenz aufrufen, wird eine Verbindung zu den
              Vimeo-Servern hergestellt und die Information übermittelt, welche
              unserer Internetseiten besucht wurde. Sind Sie in diesem Moment
              als Mitglied bei Vimeo eingeloggt, ordnet Vimeo diese Information
              Ihrem persönlichen Benutzerkonto zu. Bei Anklicken des
              Start-Buttons eines Videos wird diese Information ebenfalls Ihrem
              Benutzerkonto zugeordnet. Sie können diese Zuordnung verhindern,
              indem Sie sich vor der Nutzung unserer Internetseite aus ihrem
              Vimeo-Benutzerkonto abmelden und die entsprechenden Cookies von
              Vimeo löschen. Zweck und Umfang der Datenerhebung und die weitere
              Verarbeitung und Nutzung der Daten durch Vimeo sowie Ihre
              diesbezüglichen Rechte und Einstellungsmöglichkeiten zum Schutz
              Ihrer Privatsphäre entnehmen Sie bitte den Datenschutzhinweisen
              von Vimeo: https://vimeo.com/privacy
            </div>
            <div class="spacer"></div>
            <div class="boldfont">e) Kontaktaufnahme per E-Mail</div>
            <div>
              Wenn Sie mit uns per E-Mail Kontakt aufnehmen, verwenden wir Ihren
              Namen und Ihre Mailadresse zur Beantwortung Ihrer Anfrage.
            </div>
            <div class="spacer"></div>
          </div>
        </div>
        <div class="section">
          <div class="subheadline">
            4. Zweck der Verarbeitung und Rechtsgrundlage
          </div>
          <div class="text">
            <div>
              Wir verarbeiten personenbezogene Daten gemäß den Bestimmungen der
              Europäischen Datenschutzgrundverordnung (DSGVO) und dem
              Bundesdatenschutzgesetz (BDSG).
            </div>
            <div class="spacer"></div>
            <div class="boldfont">
              a) Zur Erfüllung vertraglicher Pflflichten (Art. 6 Abs. 1 b) DSGVO
            </div>
            <div>
              Die Verarbeitung von Daten, die Sie uns bei Anfragen zur
              Angebotserstellung oder Erteilung eines Auftrags mitteilen,
              erfolgt zur Erbringung unserer vertraglichen Leistungen oder zur
              Durchführung vorvertraglicher Maßnahmen, die auf Anfrage hin
              erfolgen.
            </div>
            <div class="spacer"></div>
            <div class="boldfont">
              b) Im Rahmen der Interessenabwägung (Art. 6 Abs. 1 f) DSGVO)
            </div>
            <div>
              Über die Nutzung zur eigentlichen Vertragserfüllung hinaus kann es
              erforderlich sein, Ihre Daten zur Wahrung berechtigter Interessen
              von uns oder Dritten zu verarbeiten, siehe oben 3 a) und b), z.B.
            </div>
            <div class="spacer"></div>
            <ul class="list">
              <li>Gewährleistung der IT-Sicherheit unseres Systems</li>
              <li>Verbesserung der Nutzbarkeit unserer Internetseite</li>
              <li>Durchsetzung von Ansprüchen</li>
            </ul>
            <div class="spacer"></div>
            <div class="boldfont">
              c) Aufgrund Ihrer Einwilligung (Art. 6 Abs. 1 a) DSGVO)
            </div>
            <div>
              Für bestimmte Zwecke (z.B. Zusendung von Werbematerial) benötigen
              wir Ihre Einwilligung. Eine Verarbeitung findet insoweit nur
              statt, wenn Sie diese Einwilligung ausdrücklich erteilt haben. Sie
              können Ihre Einwilligung jederzeit widerrufen. Der Widerruf
              berührt nicht die Rechtmäßigkeit der Verarbeitung vor Zugang des
              Widerrufs.
            </div>
          </div>
        </div>
        <div class="section">
          <div class="subheadline">5. Empfänger der Daten</div>
          <div class="text">
            Die beim Besuch unserer Webseite erfassten Daten leiten wir nicht an
            Dritte weiter. Auch Daten, die Sie uns im Rahmen einer Anfrage per
            Mail mitteilen, werden bei und durch uns verarbeitet. Nur wenn Sie
            uns ausdrücklich dazu ermächtigt haben, Ihre Daten (z.B. an einen
            unserer Lieferanten) an einen Dritten zu übermitteln, tun wir dies.
            Im Rahmen der Vertragserfüllung geben wir die zur Ausführung unserer
            Leistungen an Dritte, z.B. beauftragte Subunternehmer oder
            Transportdienstleister weiter. Wir übermitteln Daten auch an
            Behörden, wenn und soweit wir aufgrund gesetzlicher oder
            behördlicher Vorgaben hierzu verpflflichtet sind.
          </div>
        </div>
        <div class="section">
          <div class="subheadline">
            6. Übermittlung von Daten in ein Drittland
          </div>
          <div class="text">
            An Empfänger mit Sitz außerhalb der EU und des EWR übermitteln wir
            Daten nur mit Ihrer Einwilligung und soweit dies nach der Art. 44
            ff. DSGVO gestattet ist.
          </div>
        </div>
        <div class="section">
          <div class="subheadline">7. Dauer der Speicherung</div>
          <div class="text">
            <div>
              Anfragen per E-Mail löschen wir, sobald wir die Anfrage
              beantwortet haben, und der Anlass der Anfrage erledigt ist.
            </div>
            <div class="spacer"></div>
            <div>
              Übersenden Sie uns Daten, die für die Erfüllung vertraglicher und
              gesetzlicher Pflflichten erforderlich sind, dann speichern wir
              diese nur solange, bis die Pflflichten erfüllt sind.
            </div>
            <div class="spacer"></div>
            <div>
              Sind die Daten hierfür nicht mehr erforderlich, werden sie
              regelmäßig gelöscht. Eine Löschung erfolgt nicht, soweit
            </div>
            <div class="spacer"></div>
            <div>
              Handels- und steuerrechtliche Aufbewahrungspflflichten
              entgegenstehen, Daten zum Nachweis von Forderungen aus einem
              Vertragsverhältnis erhalten bleiben müssen, im Rahmen der
              regelmäßigen Verjährungsfrist (3 Jahre, § 195 BGB).
            </div>
          </div>
        </div>
        <div class="section">
          <div class="subheadline">8. Sicherheit Ihrer Daten</div>
          <div class="text">
            Wir treffen alle angemessenen technisch-organisatorische Maßnahmen,
            um den Schutz Ihrer Daten zu gewährleisten.
          </div>
        </div>
        <div class="section">
          <div class="subheadline">9. Ihre Datenschutzrechte</div>
          <div class="text">
            Als „betroffene Person“ haben Sie das Recht auf Auskunft nach Art.
            15 DSGVO, das Recht auf Berichtigung nach Art. 16 DSGVO, das Recht
            auf Löschung nach Art. 17 DSGVO, das Recht auf Einschränkung der
            Verarbeitung nach Art. 18 DSGVO, das Recht auf Widerspruch aus Art.
            21 DSGVO sowie das Recht auf Datenübertragbarkeit aus Art. 20 DSGVO.
            Beim Auskunftsrecht und beim Löschungsrecht gelten die
            Einschränkungen nach §§ 34 und 35 BDSG (n.F.). Sie haben auch das
            Recht, sich bei der zuständigen Datenschutzbehörde zu beschweren
            (Art. 77 DSGVO i.V. m. § 19 BDSG (n.F.)).
          </div>
        </div>
        <div class="section">
          <div class="subheadline">
            10. Pflflicht zur Bereitstellung von Daten
          </div>
          <div class="text">
            Sie sind weder gesetzlich, noch vertraglich verpflflichtet, uns
            Daten beim Besuch unserer Internetseite zur Verfügung zu stellen.
            Zur Beantwortung Ihrer Anfragen benötigen wir Ihren Namen und Ihre
            Mail-Adresse. Wenn Sie uns mit der Erbringung von Werkleistungen
            beauftragen möchten, ist es erforderlich, dass Sie uns diejenigen
            Daten (wie Namen und Anschrift) mitteilen, die für die Erbringung
            dieser Leistungen benötigt werden. Ohne diese Daten ist eine
            Beauftragung nicht möglich.
          </div>
        </div>
        <div class="section">
          <div class="subheadline">
            11. Automatisierte Entscheidungsfindung und Profiling
          </div>
          <div class="text">
            Eine Automatisierte Entscheidungsfindung und/oder Profiling im Sinne
            von Art. 22 Abs. 1 und 4 DSGVO finden nicht statt.
          </div>
        </div>
        <div class="section">
          <div class="subheadline">12. Fragen zum Datenschutz</div>
          <div class="text">
            Wenn Sie Fragen oder Anmerkungen zum Thema Datenschutz haben, senden
            Sie uns eine E-Mail an unsere im Impressum hinterlegte
            Kontaktadresse.
          </div>
        </div>
        <div class="section">
          <div class="subheadline">13. Änderungen der Datenschutzerklärung</div>
          <div class="text">
            <div>
              Wir behalten uns vor, die Regelungen unserer Datenschutzerklärung
              von Zeit zu Zeit anzupassen. Ältere Fassungen der
              Datenschutzerklärung können Sie über die o.g. Mailadresse bei uns
              anfordern.
            </div>
            <div class="spacer"></div>
            <div>V 2.0 Berlin im Oktober 2019</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Text;
