import "./Blackpaper.scss";
import Layout from "../Layout/Layout";
import heroImage from "../../assets/image/blackpaper/hero.png";
import Hero from "../prebuild-sections/Hero/Hero";
import Separator from "../prebuild-sections/Separator/Separator";
import Headline from "../prebuild-sections/Headline/Headline";
import { useSelector } from "react-redux";
import CustomContainer from "../prebuild-sections/CustomContainer/CustomContainer";
import { getUUID } from "../../utility";
import Item from "./Item/Item";
import MetaTags from "../prebuild-sections/MetaTags/MetaTags";

const Blackpaper = () => {
  const translation = useSelector((s) => s.translation);

  return (
    <Layout>
      <MetaTags
        title={translation.blackpaper.title}
        description={translation.blackpaper.headline}
        image={heroImage}
      />
      <div id="blackpaper">
        <Hero img={heroImage} />
        <CustomContainer>
          <Headline>
            <div className="headline-content">
              {translation.blackpaper.headline}
            </div>
          </Headline>
          <div className="intro">{translation.blackpaper.intro}</div>
          <Separator />
          <div className="item-list">
            {translation.blackpaper.items.map((item, i, items) => (
              <Item
                key={getUUID()}
                item={item}
                index={items.length - (i + 1)}
              />
            ))}
          </div>
        </CustomContainer>
      </div>
    </Layout>
  );
};

export default Blackpaper;
