import './Layout.scss'
import ResponsiveState from './ResponsiveState/ResponsiveState'
import Footer from './Footer/Footer'
import Header from './Header/Header'

const Layout = ({ children, noLogo = false }) => {
  return (
    <div id="layout">
      <ResponsiveState />
      <Header noLogo={noLogo} />
      {children}
      <Footer />
    </div>
  )
}

export default Layout
