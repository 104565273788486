import "./ItemList.scss";
import { getUUID } from "../../../utility";

const ItemList = ({ images, items }) => {
  return (
    <div className="what-we-do-item-list">
      {items.map((item, i) => (
        <div className="item" key={getUUID()}>
          <div className="img">
            <img src={images[i]} alt={item.headline} />
          </div>
          <div className="headline">{item.headline}</div>
          <div className="description">{item.text}</div>
        </div>
      ))}
    </div>
  );
};

export default ItemList;
