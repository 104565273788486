import './GetToKnow.scss'
import videoIcon from '../../../assets/image/video.png'
import whiteboardIcon from '../../../assets/image/whiteboard.png'
import { useSelector } from 'react-redux'
import ShowIfVisible from '../../animations/ShowIfVisible'
import slideUp from '../../animations/slideUp'

const GetToKnow = () => {
  const translation = useSelector((s) => s.translation)
  return (
    <ShowIfVisible animation={slideUp}>
      <div className="get-to-know">
        <a
          className="section"
          href="https://go.oncehub.com/ANCHORWORKSHOP"
          target="_blank"
          rel="noreferrer"
        >
          <div className="content">
            <div className="header">
              <div className="img">
                <img src={whiteboardIcon} alt="" />
              </div>
              <div className="headline">
                {translation.anchorworkshop.getToKnow.section1.headline}
              </div>
            </div>
            <div className="text">{translation.anchorworkshop.getToKnow.section1.text}</div>
          </div>
          <div className="cta">{translation.anchorworkshop.getToKnow.section1.cta}</div>
        </a>
        <a
          className="section"
          href="https://go.oncehub.com/EmanuelGrigorescu"
          target="_blank"
          rel="noreferrer"
        >
          <div className="content">
            <div className="header">
              <div className="img">
                <img src={videoIcon} alt="" />
              </div>
              <div className="headline">
                {translation.anchorworkshop.getToKnow.section2.headline}
              </div>
            </div>
            <div className="text">{translation.anchorworkshop.getToKnow.section2.text}</div>
          </div>
          <div className="cta">{translation.anchorworkshop.getToKnow.section2.cta}</div>
        </a>
      </div>
    </ShowIfVisible>
  )
}

export default GetToKnow
