import Template from './Template/Template'
import hero from '../../assets/image/branches/energyInfrastructure/hero.png'

// trausted brand images
import bmw_logo from '../../assets/image/branches/energyInfrastructure/logos/bmw_logo.png'
import initia_logo from '../../assets/image/branches/energyInfrastructure/logos/4initia_logo.png'
import europeanaquavillage_logo from '../../assets/image/branches/energyInfrastructure/logos/europeanaquavillage_logo.png'
import jade_logo from '../../assets/image/branches/energyInfrastructure/logos/jade_logo.png'
import sichert_logo from '../../assets/image/branches/energyInfrastructure/logos/sichert_logo.png'

// bulletpoint images
import blackflagship_markenagentur_0000_Infrastruktur from '../../assets/image/branches/energyInfrastructure/gallery/blackflagship_markenagentur_0000_Infrastruktur.png'
import blackflagship_markenagentur_0008_Infrastruktur from '../../assets/image/branches/energyInfrastructure/gallery/blackflagship_markenagentur_0008_Infrastruktur.png'
import blackflagship_markenagentur_0009_Infrastruktur from '../../assets/image/branches/energyInfrastructure/gallery/blackflagship_markenagentur_0009_Infrastruktur.png'

import hexSVG1 from '../../assets/image/branches/energyInfrastructure/svgs/Energie.svg'
import hexSVG2 from '../../assets/image/branches/energyInfrastructure/svgs/Energie_1.svg'
import hexSVG3 from '../../assets/image/branches/energyInfrastructure/svgs/Energie_2.svg'
import hexSVG4 from '../../assets/image/branches/energyInfrastructure/svgs/Energie_3.svg'
import hexSVG5 from '../../assets/image/branches/energyInfrastructure/svgs/Energie_4.svg'

const EnergyInfrastructure = () => {
  const trustedBrandImages = [
    europeanaquavillage_logo,
    initia_logo,
    bmw_logo,
    jade_logo,
    sichert_logo,
  ]
  const bulletpointImages = [
    blackflagship_markenagentur_0000_Infrastruktur,
    blackflagship_markenagentur_0008_Infrastruktur,
    blackflagship_markenagentur_0009_Infrastruktur,
  ]

  const hexSvgs = [hexSVG1, hexSVG2, hexSVG3, hexSVG4, hexSVG5]

  return (
    <Template
      translationKeyword={'energyinfrastructure'}
      hero={hero}
      svgs={hexSvgs}
      trustedBrandImages={trustedBrandImages}
      bulletpointImages={bulletpointImages}
    />
  )
}

export default EnergyInfrastructure
