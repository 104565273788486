import { useSelector } from "react-redux";
import "./Item.scss";
import { addMissingZero } from "../../../utility";

const Item = ({ item, index }) => {
  const translation = useSelector((s) => s.translation);
  const { headline, text } = item;
  const link = `/static-assets/blackpapers/${addMissingZero(
    index + 1
  )}_BLACKPAPER.pdf`;
  return (
    <div className="blackpaper-item">
      <a href={link} target="_blank" rel="noreferrer">
        <div className="thumbnail-wrapper">
          <div className="download-overlay">
            <div>{translation.blackpaper.item.thumbnail.hover.text1}</div>
            <div>{translation.blackpaper.item.thumbnail.hover.text2}</div>
          </div>
          <div className="content">
            <div className="headline">{headline}</div>
            <div className="text">{text}</div>
          </div>
          <div className="index">{index + 1}</div>
        </div>
      </a>
      <div className="content">
        <div className="headline">{headline}</div>
        <div className="text">{text}</div>
      </div>
    </div>
  );
};

export default Item;
