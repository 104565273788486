import MetaTags from 'react-meta-tags'
import { useSelector } from 'react-redux'

const MT = ({ title, description, image }) => {
  const translation = useSelector((s) => s.translation)
  return (
    <MetaTags>
      <title>
        {title} - {translation.project.pageTitleSuffix}
      </title>
      <meta
        name="title"
        property="og:title"
        content={`${title} - ${translation.project.pageTitleSuffix}`}
      />
      <meta property="og:url" content={window.location} />
      <meta name="description" property="og:description" content={description} />
      <meta name="image" property="og:image" content={window.location.origin + image} />
    </MetaTags>
  )
}

export default MT
