import './WhatWeDo.scss'
import Layout from '../Layout/Layout'
import heroImage from '../../assets/image/what-we-do/hero.png'
import Hero from '../prebuild-sections/Hero/Hero'
import Separator from '../prebuild-sections/Separator/Separator'
import Headline from '../prebuild-sections/Headline/Headline'
import { useSelector } from 'react-redux'
import CustomContainer from '../prebuild-sections/CustomContainer/CustomContainer'
import HexItem from './HexItem/HexItem'
import anchor from '../../assets/image/anchor.png'
import QuickSection from './QuickSection/QuickSection'
import head from '../../assets/image/what-we-do/head.png'
import plan from '../../assets/image/what-we-do/plan.png'
import horse from '../../assets/image/what-we-do/horse.png'
import target from '../../assets/image/what-we-do/target.png'
import ItemList from './ItemList/ItemList'
import pear from '../../assets/image/what-we-do/pear.png'
import bridge from '../../assets/image/what-we-do/bridge.png'
import thumbsup from '../../assets/image/what-we-do/thumbsup.png'
import world from '../../assets/image/what-we-do/world.png'
import whiteboardIcon from '../../assets/image/whiteboard.png'
import cyrcle from '../../assets/image/what-we-do/cyrcle.png'
import calculator from '../../assets/image/what-we-do/calculator.png'
import checklist from '../../assets/image/what-we-do/checklist.png'
import MetaTags from '../prebuild-sections/MetaTags/MetaTags'
import { useState } from 'react'
import svg1 from '../../assets/image/what-we-do/Element 78.svg'
import svg2 from '../../assets/image/what-we-do/Element 79.svg'
import svg3 from '../../assets/image/what-we-do/Element 80.svg'
import svg4 from '../../assets/image/what-we-do/Element 81.svg'
import ShowIfVisible from '../animations/ShowIfVisible'
import slideUp from '../animations/slideUp'

const WhatWeDo = () => {
  const translation = useSelector((s) => s.translation)
  const [activeSectionIndex, setActiveSectionIndex] = useState(null)

  return (
    <Layout>
      <MetaTags
        title={translation.whatWeDo.title}
        description={translation.whatWeDo.headline1}
        image={heroImage}
      />
      <div id="whatwedo">
        <Hero img={heroImage} />
        <CustomContainer>
          <Headline>{translation.whatWeDo.headline1}</Headline>
          <ShowIfVisible animation={slideUp}>
            <div className="hex-items">
              {/* {translation.whatWeDo.hexItems.map((text, i) => (
              <HexItem key={getUUID()} text={text} isOdd={i % 2 === 1} />
            ))} */}
              <HexItem svg={svg1} isOdd={false} />
              <HexItem svg={svg2} isOdd={true} />
              <HexItem svg={svg3} isOdd={false} />
              <HexItem svg={svg4} isOdd={true} />
            </div>
          </ShowIfVisible>
          <Separator />
          <div className="anchor-icon">
            <img src={anchor} alt="anchor" />
          </div>
          <Headline>{translation.whatWeDo.headline2}</Headline>
          <div className="text first">{translation.whatWeDo.missionText1}</div>
          <div className="text">{translation.whatWeDo.missionText2}</div>
          <div className="text">{translation.whatWeDo.missionText3}</div>
          <div className="strategy-cta-wrapper">
            <a href="/anchorstrategy" className="strategy-cta">
              {translation.whatWeDo.strategyCTA}
            </a>
          </div>
          <Separator />
          <QuickSection setActiveSectionIndex={setActiveSectionIndex} />
          <Separator />
          {activeSectionIndex === 0 && (
            <>
              <Headline center={true}>
                <div className="custom-headline-content">{translation.whatWeDo.headline3}</div>
              </Headline>
              <ItemList
                images={[head, plan, horse, target]}
                items={translation.whatWeDo.strategyIdentityItems}
              />
              <Separator />
            </>
          )}
          {activeSectionIndex === 1 && (
            <>
              <Headline center={true}>
                <div className="custom-headline-content">{translation.whatWeDo.headline4}</div>
              </Headline>
              <ItemList
                images={[pear, bridge, thumbsup, world]}
                items={translation.whatWeDo.designExperienceItems}
              />
              <Separator />
            </>
          )}
          {activeSectionIndex === 2 && (
            <>
              <Headline center={true}>
                <div className="custom-headline-content">{translation.whatWeDo.headline5}</div>
              </Headline>
              <ItemList
                images={[whiteboardIcon, cyrcle, calculator, checklist]}
                items={translation.whatWeDo.consultingIntegrationItems}
              />
              <Separator />
            </>
          )}
          <div className="strategy-cta-wrapper">
            <a href="/anchorstrategy" className="strategy-cta">
              {translation.whatWeDo.strategyCTA}
            </a>
          </div>
        </CustomContainer>
      </div>
    </Layout>
  )
}

export default WhatWeDo
