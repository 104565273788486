import "./Characteristics.scss";
import trash from "../../../assets/image/who-we-are/trash.png";
import fingerprint from "../../../assets/image/who-we-are/fingerprint.png";
import question from "../../../assets/image/who-we-are/question.png";
import bullshit from "../../../assets/image/who-we-are/bull.png";
import copypaste from "../../../assets/image/who-we-are/copypaste.png";
import { useSelector } from "react-redux";
import { getUUID } from "../../../utility";

const Characteristics = () => {
  const images = [trash, fingerprint, question, bullshit, copypaste];
  const translation = useSelector((s) => s.translation);

  return (
    <div className="who-we-are-characteristics">
      {translation.whoWeAre.characteristics.map((item, i) => (
        <div className="item" key={getUUID()}>
          <div className="img">
            <img src={images[i]} alt={item.headline} />
          </div>
          <div className="headline">{item.headline}</div>
          <div className="text">{item.text}</div>
        </div>
      ))}
    </div>
  );
};

export default Characteristics;
