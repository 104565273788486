import separatorImg from "../../../assets/image/global/separator.png";
import "./Separator.scss";

const Separator = () => {
  return (
    <div className="custom-separator">
      <img src={separatorImg} alt="separator" />
    </div>
  );
};

export default Separator;
