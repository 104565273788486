import './WhoWeAre.scss'
import Layout from '../Layout/Layout'
import heroImage from '../../assets/image/who-we-are/hero.png'
import Hero from '../prebuild-sections/Hero/Hero'
import Separator from '../prebuild-sections/Separator/Separator'
import Headline from '../prebuild-sections/Headline/Headline'
import { useSelector } from 'react-redux'
import CustomContainer from '../prebuild-sections/CustomContainer/CustomContainer'
import HexItem from './HexItem/HexItem'
import separationImage from '../../assets/image/who-we-are/separation.png'
import wheel from '../../assets/image/wheel.png'
import Slider from './Slider/Slider'
import Characteristics from './Characteristics/Characteristics'
import MetaTags from '../prebuild-sections/MetaTags/MetaTags'
import hexSVG79 from '../../assets/image/who-we-are/Element 79.svg'
import hexSVG80 from '../../assets/image/who-we-are/Element 80.svg'
import hexSVG81 from '../../assets/image/who-we-are/Element 81.svg'
import hexSVG82 from '../../assets/image/who-we-are/Element 82.svg'
import hexSVG83 from '../../assets/image/who-we-are/Element 83.svg'
import hexSVG84 from '../../assets/image/who-we-are/Element 84.svg'
import hexSVG85 from '../../assets/image/who-we-are/Element 85.svg'
import hexSVG86 from '../../assets/image/who-we-are/Element 86.svg'
import hexSVG87 from '../../assets/image/who-we-are/Element 87.svg'
import ShowIfVisible from '../animations/ShowIfVisible'
import slideUp from '../animations/slideUp'

const WhoWeAre = () => {
  const translation = useSelector((s) => s.translation)

  return (
    <Layout>
      <MetaTags
        title={translation.whoWeAre.title}
        description={translation.whoWeAre.headline1}
        image={heroImage}
      />
      <div id="whoweare">
        <Hero img={heroImage} />
        <CustomContainer>
          <Headline>{translation.whoWeAre.headline1}</Headline>
          <ShowIfVisible animation={slideUp}>
            <div className="separation-image">
              <img src={separationImage} alt="" />
            </div>
          </ShowIfVisible>
          <Separator />
          <div className="statements">
            <Headline>
              <div className="headline-content">{translation.whoWeAre.headline2}</div>
            </Headline>
            <Slider />
          </div>
          <Separator />
          <div className="wheel-wrapper">
            <img src={wheel} alt="wheel" />
          </div>
          <Headline>{translation.whoWeAre.headline3}</Headline>
          <div className="subheadline">
            <span className="bold">BLACK</span>FLAGSHIP - die Markenagentur für erfolgreichen
            Marken-Aufbau, spezifische Marken-Kommunikation & adäquate Marken-Positionierung.
          </div>
          <Characteristics />
          <Separator />
          <Headline>
            <div className="headline-content">{translation.whoWeAre.headline4}</div>
          </Headline>
          <CustomContainer>
            <div className="hex-items">
              <HexItem svg={hexSVG79} />
              <HexItem svg={hexSVG80} />
              <HexItem svg={hexSVG81} />
              <HexItem svg={hexSVG82} />
              <HexItem svg={hexSVG83} />
              <HexItem svg={hexSVG84} />
              <HexItem svg={hexSVG85} />
              <HexItem svg={hexSVG86} />
              <HexItem svg={hexSVG87} />
            </div>
          </CustomContainer>
          <Separator />
          <div className="meeting-cta-wrapper">
            <a href="/anchorworkshop" className="meeting-cta">
              {translation.whoWeAre.meetingCTA}
            </a>
          </div>
        </CustomContainer>
      </div>
    </Layout>
  )
}

export default WhoWeAre
