import Template from './Template/Template'
import hero from '../../assets/image/branches/foodbaverages/hero.png'

// trausted brand images
import black_logo from '../../assets/image/branches/foodbaverages/logos/28black_logo.png'
import europeanaquavillage_logo from '../../assets/image/branches/foodbaverages/logos/europeanaquavillage_logo.png'
import ohde_logo from '../../assets/image/branches/foodbaverages/logos/ohde_logo.png'
import oleove_logo from '../../assets/image/branches/foodbaverages/logos/oleove_logo.png'
import scaviray_logo from '../../assets/image/branches/foodbaverages/logos/scaviray_logo.png'
import wabisabi_logo from '../../assets/image/branches/foodbaverages/logos/wabisabi_logo.png'

// bulletpoint images
import blackflagship_markenagentur_0001_Food from '../../assets/image/branches/foodbaverages/gallery/blackflagship_markenagentur_0001_Food.png'
import blackflagship_markenagentur_0007_Food from '../../assets/image/branches/foodbaverages/gallery/blackflagship_markenagentur_0007_Food.png'
import blackflagship_markenagentur_0029_food_1 from '../../assets/image/branches/foodbaverages/gallery/blackflagship_markenagentur_0029_food_1.png'

import hexSVG1 from '../../assets/image/branches/foodbaverages/svgs/Food_1.svg'
import hexSVG2 from '../../assets/image/branches/foodbaverages/svgs/Food_2.svg'
import hexSVG3 from '../../assets/image/branches/foodbaverages/svgs/Food_3.svg'
import hexSVG4 from '../../assets/image/branches/foodbaverages/svgs/Food_4.svg'
import hexSVG5 from '../../assets/image/branches/foodbaverages/svgs/Food_5.svg'

const FoodBeverages = () => {
  const trustedBrandImages = [
    black_logo,
    europeanaquavillage_logo,
    ohde_logo,
    oleove_logo,
    scaviray_logo,
    wabisabi_logo,
  ]
  const bulletpointImages = [
    blackflagship_markenagentur_0001_Food,
    blackflagship_markenagentur_0007_Food,
    blackflagship_markenagentur_0029_food_1,
  ]

  const hexSvgs = [hexSVG1, hexSVG2, hexSVG3, hexSVG4, hexSVG5]

  return (
    <Template
      translationKeyword={'foodbeverages'}
      hero={hero}
      svgs={hexSvgs}
      trustedBrandImages={trustedBrandImages}
      bulletpointImages={bulletpointImages}
    />
  )
}

export default FoodBeverages
