import Template from './Template/Template'
import hero from '../../assets/image/branches/finance/hero.png'

// trausted brand images
import allianz_logo from '../../assets/image/branches/finance/logos/allianz_logo.png'
import degussa_logo from '../../assets/image/branches/finance/logos/degussa_logo.png'
import hilpert_logo from '../../assets/image/branches/finance/logos/hilpert_logo.png'
import hyprofinance_logo from '../../assets/image/branches/finance/logos/hyprofinance_logo.png'
import ubs_logo from '../../assets/image/branches/finance/logos/ubs_logo.png'

// bulletpoint images

import blackflagship_markenagentur_0003_Finanzen from '../../assets/image/branches/finance/gallery/blackflagship_markenagentur_0003_Finanzen.png'
import blackflagship_markenagentur_0014_FInanzen_1 from '../../assets/image/branches/finance/gallery/blackflagship_markenagentur_0014_FInanzen_1.png'

import hexSVG1 from '../../assets/image/branches/finance/svgs/Finanzen_1.svg'
import hexSVG2 from '../../assets/image/branches/finance/svgs/Finanzen_2.svg'
import hexSVG3 from '../../assets/image/branches/finance/svgs/Finanzen_3.svg'
import hexSVG4 from '../../assets/image/branches/finance/svgs/Finanzen_4.svg'
import hexSVG5 from '../../assets/image/branches/finance/svgs/Finanzen_5.svg'

const Finance = () => {
  const trustedBrandImages = [allianz_logo, degussa_logo, hilpert_logo, ubs_logo, hyprofinance_logo]
  const bulletpointImages = [
    blackflagship_markenagentur_0003_Finanzen,
    blackflagship_markenagentur_0014_FInanzen_1,
  ]

  const hexSvgs = [hexSVG1, hexSVG2, hexSVG3, hexSVG4, hexSVG5]

  return (
    <Template
      translationKeyword={'finance'}
      hero={hero}
      svgs={hexSvgs}
      trustedBrandImages={trustedBrandImages}
      bulletpointImages={bulletpointImages}
    />
  )
}

export default Finance
