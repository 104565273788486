import "./Headline.scss";

const Headline = ({ children, center = false }) => {
  return (
    <div className={`custom-headline${center ? " center" : ""}`}>
      {children}
    </div>
  );
};

export default Headline;
