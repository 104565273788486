const Text = () => {
  return (
    <div className="text">
      <div class="container">
        <div class="headline">IMPRESSUM</div>
        <div class="text">
          <div class="boldfont">Blackflagship Markenagentur GmbH</div>
          <div>Alt-Tempelhof 37</div>
          <div>12103 Berlin</div>
          <div>Tel.: +49 (0) 30 75 65 37 92</div>
          <div class="spacer"></div>
          <div>E-Mail: info@blackflagship.com</div>
          <div>Web: www.blackflagship.com</div>
          <div class="spacer"></div>
          <div>
            Firmensitz: Berlin // Amtsgericht Tempelhof- Schöneberg HRB 180091 B
            Geschäftsführer: E. Grigorescu
          </div>
          <div class="spacer"></div>
          <div>Konzeption und Design: Blackflagship</div>
          <div class="spacer"></div>
          <div>Angaben gemäß § 5 TMG, § 55 Abs. 2 RStV und § 2 DL-InfoV</div>
        </div>
        <div class="section">
          <div class="subheadline">Haftungsausschluss</div>
          <div class="text">
            Die Inhalte unserer Seiten wurden mit größter Sorgfalt erstellt. Für
            die Richtigkeit, Vollständigkeit und Aktualität der Inhalte können
            wir jedoch keine Gewähr übernehmen. Als Dienste-Anbieter sind wir
            gemäß § 7 Abs.1 TMG für eigene Inhalte auf diesen Seiten nach den
            allgemeinen Gesetzen verantwortlich. Nach §§ 8 bis 10 TMG sind wir
            als Dienste-Anbieter jedoch nicht verpflichtet, übermittelte oder
            gespeicherte fremde Informationen zu überwachen oder nach Umständen
            zu forschen, die auf eine rechtswidrige Tätigkeit hinweisen.
            Verpflichtungen zur Entfernung oder Sperrung der Nutzung von
            Informationen nach den allgemeinen Gesetzen bleiben hier- von
            unberührt. Eine diesbezügliche Haftung ist jedoch erst ab dem
            Zeitpunkt der Kenntnis einer konkreten Rechtsverletzung möglich. Bei
            Bekanntwerden von entsprechenden Rechtsverletzungen werden wir diese
            Inhalte umgehend entfernen.
          </div>
        </div>
        <div class="section">
          <div class="subheadline">Verantwortlich für den Inhalt:</div>
          <div class="text">
            <div>
              Emanuel Grigorescu und Milan Grujic, Geschäftsführung
              Alt-Tempelhof 37, D-12103 Berlin
            </div>
            <div class="spacer"></div>
            <div>
              Haftungshinweis für Links
              <br />
              Unser Angebot enthält Links zu externen Webseiten Dritter, auf
              deren Inhalte wir keinen Einfluss haben. Deshalb können wir für
              diese fremden Inhalte auch keine Gewähr übernehmen. Für die
              Inhalte der verlinkten Seiten ist stets der jeweilige Anbieter
              oder Betreiber der Seiten verantwortlich. Die verlinkten Seiten
              wurden zum Zeitpunkt der Verlinkung auf mögliche Rechtsverstöße
              überprüft. Rechtswidrige Inhalte waren zum Zeitpunkt der
              Verlinkung nicht erkennbar. Eine permanente inhaltliche Kontrolle
              der verlinkten Seiten ist jedoch ohne konkrete Anhaltspunkte einer
              Rechtsverletzung nicht zumutbar. Bei Bekanntwerden von
              Rechtsverletzungen werden wir derartige Links umgehend entfernen.
            </div>
          </div>
        </div>
        <div class="section">
          <div class="subheadline">Urheberrecht</div>
          <div class="text">
            Die durch die Seitenbetreiber erstellten Inhalte und Werke auf
            diesen Seiten unterliegen dem deutschen Urheberrecht. Die
            Vervielfältigung, Bearbeitung, Verbreitung und jede Art der
            Verwertung außerhalb der Grenzen des Urheberrechtes bedürfen der
            schriftlichen Zustimmung des jeweiligen Autors bzw. Erstellers.
            Downloads und Kopien dieser Seite sind nur für den privaten, nicht
            kommerziellen Gebrauch gestattet. Soweit die Inhalte auf dieser
            Seite nicht vom Betreiber erstellt wurden, werden die Urheberrechte
            Dritter beachtet. Insbesondere werden Inhalte Dritter als solche
            gekennzeichnet. Sollten Sie trotzdem auf eine
            Urheberrechtsverletzung aufmerksam werden, bitten wir um einen
            entsprechenden Hinweis. Bei Bekanntwerden von Rechtsverletzungen
            werden wir derartige Inhalte umgehend entfernen.
          </div>
        </div>
        <div class="section">
          <div class="subheadline">Datenschutz</div>
          <div class="text">
            <div>
              Die Nutzung unserer Webseite ist in der Regel ohne Angabe
              personenbezogener Daten möglich. Soweit auf unseren Seiten
              personenbezogene Daten (beispielsweise Name, Anschrift oder
              eMail-Adressen) erhoben werden, erfolgt dies, soweit möglich,
              stets auf freiwilliger Basis. Diese Daten werden ohne Ihre
              ausdrückliche Zustimmung nicht an Dritte weitergegeben.
            </div>
            <div class="spacer"></div>
            <div>
              Wir weisen darauf hin, dass die Datenübertragung im Internet (z.B.
              bei der Kommunikation per E-Mail) Sicherheitslücken aufweisen
              kann. Ein lückenloser Schutz der Daten vor dem Zugriff durch
              Dritte ist nicht möglich.
            </div>
            <div class="spacer"></div>
            <div>
              Der Nutzung von im Rahmen der Impressumspflicht veröffentlichten
              Kontaktdaten durch Dritte zur Übersendung von nicht ausdrücklich
              angeforderter Werbung und Informationsmaterialien wird hiermit
              ausdrücklich widersprochen. Die Betreiber der Seiten behalten sich
              ausdrücklich rechtliche Schritte im Falle der unverlangten
              Zusendung von Werbeinformationen, etwa durch Spam-Mails, vor.
            </div>
          </div>
        </div>
        <div class="section">
          <div class="subheadline">Cookies</div>
          <div class="text">
            Um den Funktionsumfang unseres Internetangebotes zu erweitern und
            die Nutzung für Sie komfortabler zu gestalten, verwenden wir
            sogenannte “Cookies”. Mit Hilfe dieser “Cookies” können bei dem
            Aufruf unserer Webseite Daten auf Ihrem Rechner gespeichert werden.
            Sie haben die Möglichkeit, das Abspeichern von Cookies auf Ihrem
            Rechner durch entsprechende Einstellungen in Ihrem Browser zu
            verhindern. Hierdurch könnten allerdings der Funktionsumfang unseres
            Angebotes eingeschränkt werden.
          </div>
        </div>
      </div>
    </div>
  );
};

export default Text;
