import { keyframes } from 'styled-components'

const slideLeftToRight = keyframes`
  from {
    transform: translateX(-50px);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
  `

export default slideLeftToRight
