//
// ONLY EDIT THIS FILE IN /frontend/src/
//

const settings = {
  config: {
    slackIncomingWebhookURL:
      "https://hooks.slack.com/services/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",
  },
};

module.exports = settings;
