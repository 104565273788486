import { useSelector } from 'react-redux'

const HexItem = ({ svg, isOdd }) => {
  const translation = useSelector((state) => state.translation)

  return (
    <img src={svg} className={`hexElem ${isOdd ? 'odd' : ''}`} alt={translation.project.name}></img>
  )
}
export default HexItem
