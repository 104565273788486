//
// ONLY EDIT THIS FILE IN /frontend/src/
//

const translation = {
  project: {
    name: 'Blackflagship Markenagentur GmbH',
    pageTitleSuffix: 'BLACKFLAGSHIP',
  },

  home: {
    title: 'Home',
    headline1: 'DAS „WARUM“ FÜR PRODUKT, MARKE UND UNTERNEHMEN.',
    whyCTA: 'WIESO MIT BFS ZUSAMMENARBEITEN',
    headline2: 'BERATUNG FÜR DEINE BRANCHE.',
    branchesMore: 'WEITERE & MEHR',
    headline3: 'STIMMEN UNSERER KUNDEN & PARTNER',
    headline4: 'UNSER PERSÖNLICHES FLAGSCHIFF',
    subheadline4:
      '„LÖSE DIE HERAUSFORDERUNGEN DEINER KUNDEN“ IST DAS NEUE „TU GUTES UND SPRICH DARÜBER“',
    cta4: 'MEHR ÜBER DIE ANCHOR STRATEGY',
    customOverflowHeadline: 'QUICKSTRATEGY // ANCHORWORKSHOP',
    headline5: 'DIE BESTE ART FÜR DAS „ERSTE KENNENLERNEN“',
    cta5: 'MEHR ÜBER DEN ANCHOR WORKSHOP',
    headline6: 'AUSZUG AUS UNSEREM PORTFOLIO',
  },

  contact: {
    title: 'Kontakt',
    headline1: 'DIREKT KONTAKT AUFNEHMEN',
    headline2: 'UND EIN KENNENLERNEN VEREINBAREN',
    bulletpoints: {
      mail: 'WIR FREUEN UNS AUF DEINE MAIL',
      video: 'GEMEINSAMEN TERMIN VEREINBAREN',
      phone: 'EIN ERSTES KURZES TELEFONAT',
    },
  },

  businessnavigator: {
    title: 'Businessnavigator',
    headline:
      'TOOLS, PROZESSE & LÖSUNGSANSÄTZE, DIE WIR IN ZAHLREICHEN PROJEKTEN ANGEWENDET UND VERFEINERT HABEN.',
    filters: {
      worthreading: 'Lesenswertes',
      applydirectly: 'Direkt anwenden und profitieren',
      consulting: 'Beratungsprodukte',
      reset: 'Reset Filter',
    },
    itemsCTA: 'LINK ZUM PRODUKT',
    items: {
      strategybook: {
        headline: 'BLACKFLAGSHIP ® ANCHORSTRATEGY',
        subheadline: 'Das erste Marken-Beratungs-Produkt',
        text: '„Die richtigen Massnahmen aus den richtigen Gründen ergreifen.“ Alternative Lösungen für aktuelle Herausforderungen zum Festpreis - unabhängig von Themen & Inhalten. Ziele zeit- & budgeteffizient erreichen und Umsatzoptimierung durch neue Marktpotentiale erzielen.',
      },
      anchortactics: {
        headline: 'ANCHOR // TACTICS',
        subheadline: 'Direkter Umsetzungs-Boost für aktuelle ToDo‘s in Marketing & Sales',
        text: 'Definition der Unternehmensziele und Ausarbeitung eines 3-Monats-Umsetzungsplans zur Optimierung der Marketing- und Vertriebsaktivitäten. Umsetzung in wöchentlichen 1:1 Action-Meetings zu aktuellen Themen.',
      },
      anchorstory: {
        headline: 'ANCHOR STORY',
        subheadline: 'Trotz hochwertiger Produkte bleibt der Wettbewerb auf Augenhöhe?',
        text: 'Mit unseren Kunden-Erfolgsgeschichten unterstützen wir Unternehmen, Schlüsselpunkte zu definieren und diese in eine Geschichte zu verpacken, die sich im Kopf verankert.',
      },
      gutberaten: {
        headline: 'WORKSHOP-SALES-TOOL',
        subheadline: 'Gut beraten, statt schlecht verkaufen.',
        text: 'Mit einer Kurz-Workshop-Systematik Kunden gewinnen und langfristig binden. Durch richtige Beratung direkt am Kunden vertreiben, Dienstleistungen & Produkte live beraten, statt verkaufen und Mehrwert im Kundengespräch vermitteln.',
      },
      b2b2c: {
        headline: 'B2B/2C MARKETING:',
        subheadline: '9 Marketing-Guidelines aus dem B2C - Warum und wie man sie im B2B anwendet.',
        text: 'Jede Branche bringt individuelle Charakteristika mit sich, teilen jedoch eine Gemeinsamkeit: Es wird mit Menschen gearbeitet. Wenig Unternehmen sind sich dieser Relevanz im Kontext von Marke und Marketing bewusst.',
      },
      eventtransformation: {
        headline: 'EVENT-TRANSFORMATION',
        subheadline: '100+ Events// 2.178 Konzeptstunden// 1.63M Umsätze',
        text: '28 Eventkonzepte, 1,53GB offene Designdateien, 04 Audio-Guidelines & 04 Erklärvideos. Durch Leitfäden zur Akquisition und Anstoß von Eventprojekten & Konzeptvorlagen für innovative Events zum sofortigen Projektstart.',
      },
      image90minutes: {
        headline: 'QUICK-STRATEGY WORKSHOP',
        subheadline: 'Eine kurze Session für schnelle Klarheit bei operativen Herausforderungen',
        text: 'Garantierte Lösungsansätze durch einen gemeinsamen Kurzworksop um Herausforderungen lösen, konkrete Handlungsempfehlungen erhalten und anwendbare Optimierungen sofort diskutieren.',
      },
      toplearning: {
        headline: 'AnchorStrategy - TOP LEARNINGS:',
        subheadline: 'In 120 Sekunden teilen wir eine Erkenntnis aus der AnchorStrategy.',
        text: 'Eine Kurz-Video-Reihe zu verschiedenen Themen, die wir in den verschiedenen Anchor Strategies erarbeitet haben. Aufgabe ist, durch alternative Herangehensweisen, schnelle und umsetzungsnahe Ansätze zu aktuellen Themen einer Brand zu kreieren.',
      },
      blackpaper: {
        headline: 'BFS // BLACKPAPER:',
        subheadline: 'Blickwinkel auf aktuelle Themen für Unternehmer, Entscheider und Marketer.',
        text: '„BLACKPAPER“ von der Markenagentur Blackflagship sind... anders. Die erscheinenden Essays beleuchten aktuelle Themen und Trends aus Marketingbranche. Als Alternative zu bekannten „Whitepapers“ scheuen sie sich nicht, eine individuelle, skeptische und scharf zu sein.',
      },
    },
  },

  blackpaper: {
    title: 'Blackpaper',
    headline:
      'BLICKWINKEL AUF AKTUELLE THEMEN FÜR UNTERNEHMER, ENTSCHEIDER UND MARKETER - FRISCH & UNVERKRAMPFT',
    intro:
      '„BLACKPAPER“ von der Markenagentur Blackflagship sind... anders. Die wöchentlich erscheinenden Essays beleuchten aktuelle Themen und Trends aus Marketingbranche. Als Alternative zu bekannten „Whitepapers“ scheuen sie sich nicht, eine individuelle, skeptische und scharf fokussierte Perspektive einzunehmen. Blackpaper rütteln auf, öffnen die Augen, legen den Finger auf die Wunde: Was läuft unrund in der Welt der Brands und Markenkommunikation, wie entstehen hartnäckige Probleme und warum schleifen sich unverzeihliche Nachlässigkeiten ein? Vor allem aber: Was können Unternehmer, Entscheider und Marketer tun, um exakt diese Aufschlüsse zu nutzen und in ihrem eigenen Mindset und ihren Strategien umzusetzen? Ob Nischenmarkt oder Einzelkämpfer, Start-up oder Großkonzern: Mit den Blackpapers gewinnst du einen frischen, unverkrampften Blickwinkel auf aktuelle Taktiken und Konzepte. Du lernst, wie du die Fehler der anderen vermeidest und was du daraus für den Erfolg deines eigenen Business herausholst. Die Blackpaper nehmen – bildlich gesprochen – kein Blatt vor den Mund und zeigen (nicht immer objektiv) Irrungen und Hindernisse als warnende Beispiele auf dem Weg zum erfolgreichen Business auf. Oder, kurz gesagt: „So liegt der Fall – und so machst du es besser!“',
    item: {
      thumbnail: {
        hover: {
          text1: 'JETZT',
          text2: 'HERUNTERLADEN',
        },
      },
    },
    items: [
      {
        headline: 'DAS GESPENST DER EMPLOYERBRAND – EINE SURREALE MARKE.',
        text: 'Die „Mitarbeiter-Marke“ ist keine innovative Erfindung. Der Terminus ist seit Mitte der Neunzigerjahre gebräuchlich und beschreibt ein Mittel, mit dessen Hilfe ein Unternehmen am Markt als Arbeitgeber wahrgenommen werden soll. Dennoch stellt das Employer Branding einen Aspekt dar, der von der Mehrzahl der Unternehmen immer noch nicht richtig behandelt wird und dem man im unangemessenen Maße Beachtung schenkt. Die Employer- Brand beansprucht in der Regel im Unternehmen eine falsche Relevanz. Denn: Es gibt sie schlechterdings nicht.',
      },
      {
        headline:
          'DIE BRAND ALS „SCHUTZ“-MARKE UND KOMMUNIKATIONSTOOL (MARKE UND MARKETING, TEIL 2)',
        text: 'Die Relevanz von Brands wird in vielen Firmen immer noch unterschätzt. Eine Gleichstellung des Unternehmens als seine eigene Marke ist allerdings nicht nur aus werbetechnischen Erwägungen fatal. So manches Unternehmen verschenkt dabei die Chance, eine Brand einzusetzen, um mit erheblich mehr Potential am Markt zu agieren – im Tagesgeschäft wie im Krisenfall. Die Voraussetzung: Die Marke muss professionell „bedient“ werden. Idealerweise von einem Marketer, dessen Aufgabenbereich klar definiert ist: Als Projektmanager der Brand.',
      },
      {
        headline:
          'FACHKRAFT ODER FAKTOTUM? – DER MARKETER IM UNTERNEHMENSALLTAG (MARKE UND MARKETING, TEIL 1)',
        text: 'So gut wie jedes Unternehmen, ganz egal in welcher Branche, gönnt sich einen eigenen Marketing-Beauftragten oder gleich eine ganze Abteilung davon. Warum eigentlich? Weil es professionell wirkt? Weil alle anderen auch einen haben? Der Vollständigkeit halber? Zumindest könnte man diesen Eindruck gewinnen, denn in der Mehrzahl der Fälle macht der Marketer im Unternehmen alles mögliche – nur nicht das, was seinem Berufsbild entspricht. Kein Wunder, dass daraus nicht selten im Tagesgeschäft Reibereien oder handfeste Probleme resultieren.',
      },
      {
        headline: 'SENIOREN AUF STUDENTENFETEN: WIE MAN NEUE ZIELGRUPPEN STIMMIG ANSPRICHT.',
        text: 'Jedes Unternehmen richtet sich mit seinen Produkten oder Dienstleistungen an spezielle Adressaten und stimmt sein Marketing darauf ab. Interessant wird es, wenn sich neue Zielgruppen auftuen, aber keine passende Marketingstrategie für diese Adressaten zur Hand ist. Werbung, die ohne Rücksicht auf den adäquaten Zielgruppenbedarf ausgespielt wird, bleibt entweder unbeachtet – oder kann so richtig peinlich wirken. Umso wichtiger ist es, potentielle Kundenteilmengen differenziert zu bedienen.',
      },
      {
        headline: 'KAUFLENKUNG MIT GEFÜHL: DIE EMOTIONALE AKTIVIERUNG DES KONSUMVERHALTENS.',
        text: 'In einer Welt, in der es in quasi jedem Marktsegment Auswahl im Überfluss gibt, wird es zunehmend schwieriger, Produkte an den Kunden zu bringen. Nicht nur, dass viele Menschen sparsam konsumieren: Sie finden sich auch einem Werbebombardement ausgesetzt, das viele buchstäblich abschalten lässt – den Fernseher wie die Kaufreflexe. Für erfolgreiche Geschäfte muss eine andere Strategie her: Vom rein materiellen Bedarfskonsum zur verkaufsfördernden Emotion.',
      },
      {
        headline: 'DIE HILFREICHEN SIEBEN: VIER PLUS DREI „PS“ IM DIENSTLEISTUNGS-MARKETING.',
        text: 'Um Kunden den Kauf von Produkten schmackhaft zu machen, wird allerhand aufgefahren und um das Produkt herum eine ausgefeilte custumer journey inszeniert. Was aber, wenn das Produkt sich weder begutachten noch ohne weiteres vergleichen lässt? Etwa, weil es erst mit dem „Kauf“ entsteht und trotzdem immateriell bleibt? Kurz: Welche Faktoren bestimmen das Marketing für Dienstleistungen? Die Antwort lässt sich auf eine griffige Formel bringen: Bonus-Triple-P.',
      },
      {
        headline:
          'GOODIE-TRUBEL VERSUS COSTUMER JOURNEY: WIE PRODUKTRELEASES ZUM ERFOLGSFAKTOR WERDEN.',
        text: 'Wer eine Neuheit auf den Markt bringt oder relevante Upgrades vorstellt, tut dies – sofern das Produkt sich zur physischen Vorführung eignet – mit einem Produktrelase in Form einer Veranstaltung. Dort hat der potenzielle Kunde die Möglichkeit, die Neuheit unmittelbar zu begutachten. Allerdings: Die Motivation zur Teilnahme an Produktreleases sinkt. Kein Wunder, nachdem diese Aktionen bei vielen Brands ganz offensichtlich in der Prä-Online-Vergangenheit stecken geblieben sind.',
      },
      {
        headline: 'KEINE PRODUKTE FÜR NEUE MÄRKTE: DIE LETHARGIE DER MARKTFELDSTRATEGEN.',
        text: 'Dass die meisten Märkte hart umkämpft sind, ist kein Geheimnis. Ungeachtet dessen sollte es für ein Unternehmen selbstverständlich sein, Wachstum zu generieren, zum Beispiel mit neuen Produkten oder der Erschließung neuer Marktplätze oder Kunden-Zielgruppen. Erschreckend viele Unternehmer scheinen diese elementare Anforderung nicht auf der Agenda zu haben. Kein Wunder also, dass so viele nur zwischen ihren Mitbewerbern in bekanntem Fahrwasser herumdümpeln.',
      },
      {
        headline: 'LAUTER SCHLECHTE ANGEBOTE, ODER: WIE MAN KUNDEN IM ENDSPURT VERGRAULT.',
        text: 'Wenn der potenzielle Kunde gegen Ende seiner costumer journey ein Angebot anfordert, ist der Verkauf so gut wie abgewickelt? Von wegen! Gerade beim entscheidenden letzten Schritt vor Geschäftabschluss beginnen viele Anbieter zu schludern und wundern sich anschließend, wieso der Beinahe-Käufer sich plötzlich doch gegen das Produkt oder die Dienstleistung entschieden hat. Warum springen Kaufinteressenten von den Offerten ab? Und wieso nutzt kaum jemand die Chance, die bestehende Misere zum eigenen Vorteil zu umgehen?',
      },
      {
        headline:
          '“WER HEUTE A KAUFT, BRAUCHT ÜBERMORGEN B” AFTER-SALES-MARKETING ZUR KUNDENBINDUNG UND AKTIVIERUNG.',
        text: 'Der Verkauf ist geglückt und der Kunde ist zufrieden mit seinem neuen Konsumgut. Das ist ein Erfolg. Aber: Wie bringt man als Salesverantwortlicher den Kunden dazu, auch seinen nächsten Einkauf zu absolvieren, oder weitere Artikel zu kaufen, an denen er voraussichtlich Bedarf hat oder mittelfristig entwickeln wird? Damit der Kunde nicht auf eigene Faust auf die Suche nach dem Folgeangebot geht und immer wieder neu umworben werden muss, gibt es das „After-Sales-Marketing“ – Werbemaßnahmen NACH dem Einkauf. Praktiziert wird das allerdings kaum – bis jetzt.',
      },
      {
        headline: 'WAS SPONSOREN WIRKLICH WOLLEN: BRANCHENKONZEPTE STATT EVENT-WERBEFLÄCHE.',
        text: 'Wer kennt sie nicht: Bannerwerbung an der VIP-Loge in der Konzertarena, Probierstände beim Stadtfest, Markenlogos auf Fußballtrikots. Aber das Kultur-, Sport- und allgemeine Eventsponsoring ist ein mühsames Geschäft. Veranstalter oder Locations tun sich schwer mit dem Verkauf ihrer „Benefits“, Förderer sind frustriert über Sponsorships, die sich als Flops erweisen. Die Lösung: Ein zeitgemäßes B2B-Marketing anstelle verstaubter Akquisemethoden - Branchenkonzepte.',
      },
      {
        headline: 'PRODUKTKATEGORIE-BRANDING: PRODUKTE IM NATÜRLICHEN LEBENSRAUM.',
        text: 'Quasi jede Brand führt verschiedene Produkttypen oder Kategorien im Portfolio, die sich an unterschiedliche Käufer-Zielgruppen richten. Die gängige Methode der Vermarktung besteht in vielen Fällen allerdings einzig darin, einzelne Produkte innerhalb der eigenen Marken-Fangemeinde zu promoten. Das ist ineffektiv: Über ein individuelles Branding der Produktkategorien ließen sich zusätzliche Kunden generieren, die sich (noch) nicht für die Marke, sehr wohl aber für ein Motiv, ein Thema oder einen Benefit begeistern.',
      },
      {
        headline: 'DIE LOKALISIERUNG (INTER)- NATIONALER BRANDS.',
        text: 'Warum verschenken internationale und nationale Brands eigentlich so viel Potential? Da wird mit großer Mühe eine Marke etabliert, ein neues Produkt gelauncht, eine schicke Kampagne entwickelt – und dann bekommen potentielle Kunden rund um den Globus dieselbe Werbung und die gleichen Messages (allenfalls übersetzt in Landessprache) serviert. Diese Allzweck- Strategie entzieht im Grunde dem Point of Sale vor Ort seine Funktion. Dabei könnte ein wenig mehr „Lokalkolorit“ nicht nur das Interesse des Kunden nachhaltig steigern, sondern ebenso neue Zielgruppen erschließen.',
      },
      {
        headline: 'USP UND UCP: MEHR ALS HEISSE LUFT UND GRAUE THEORIE.',
        text: '„Unique Selling Proposition“, kurz USP, vulgo: das Alleinstellungsmerkmal ist Fundament der erfolgreichen Positionierung von Marke und Produkt. Davon hat jeder Businesstreibende schon gehört und es verinnerlicht - das behaupten zumindest erstaunlich viele. Umso verblüffender ist, dass kaum jemand eine wirklich klare Vorstellung davon hat, was ein USP ist – und wie man ihn sowohl den Kunden als auch der Belegschaft gegenüber kommuniziert. An dieser Stelle kommt der UCP ins Spiel: die „Unique Communication Proposition“.',
      },
      {
        headline: 'EMOTIONALISIERUNG AUF DER B2B-EBENE: VON KUNDESKUNDEN UND MEHRWERTRELEVANZ.',
        text: 'Die Business-to-Business (B2B)-Beziehungen zwischen einem Dienstleister oder Zulieferer und seinem Kunden sind maßgeblich von den Personen auf beiden Seiten geprägt und somit nicht selten eine Zitterpartie. Das muss nicht sein: Gelingt eine Emotionalisierung der Marke auf der Geschäftsebene, eröffnen sich ganz neue Perspektiven der Kundenbindung.',
      },
      {
        headline: 'DER TOTE WINKEL ZWISCHEN MARKE UND RETAIL.',
        text: 'Wenn eine neue Marke oder ein Produkt eingeführt werden, geht dem eine Menge Aufwand und Überlegung voran. Eine emotionale Präsentation soll Kunden die Ware schmackhaft machen. Im Internet, mit Werbung und auf Premierenevents klappt das ganz gut – am Point of Sale jedoch bleibt die Brand-Experience auf der Strecke. Doch was genau ist die Blackbox, in der alle konzeptionellen Ansätze ihre Wirkung verlieren – und kann eine Neudefinition dem Point of Sale helfen?',
      },
      {
        headline: 'DIE GESCHICHTE VOM NACHHALTIGEN SCHMUCK – UND WARUM NIEMAND SIE ERZÄHLT.',
        text: 'Nachhaltigkeit ist ein großes Thema unserer Tage. In Bezug auf Konsumgüter ist die nachhaltige Produktion sogar eines der zentralen Verkaufsargumente. Wer ressourcenschonend kauft, konsumiert mit gutem Gewissen. Das gilt auf jedem Gebiet, vom Lebensmittel bis zum Luxusgut. Allerdings: Im Hochpreisbereich tun sich Brands schwer, ihre Message vom „ethischen“ Reichtum zu verbreiten. Was läuft schief bei Schmuck und Edel-Uhren?',
      },
      {
        headline: 'DIE MARKE - JEDES UNTERNEHMEN SOLLTE EINE HABEN.',
        text: 'Die Marke – jedes Unternehmen sollte eine haben. Das Tragische: Viele Firmen sind der Meinung, auch ganz gut ohne eine eigene Brand auszukommen. Wieder andere sind der irrigen Auffassung, ein Label zu haben, praktizieren jedoch nur Halbgares zur deren Kommunikation. Aber was ist eigentlich eine Marke, was tut sie – und weshalb zahlt sich der enorme Aufwand aus, eine aufzubauen?',
      },
      {
        headline: 'E-MOBILITÄT - NEUE MARKETING-DEMANDS FÜR NEUE TECHNOLOGIEN.',
        text: 'Was läuft schief bei der Markenkommunikation für Elektromobile? Ganz einfach: Marken und Händler kommunizieren an den Bedürfnissen ihrer Zielkundschaft vorbei. Hightech vom Feinsten mit Werberoutinen von gestern promoten – das kann nicht funktionieren. Ein Paradigmenwechsel muss her: Rückt die Kundenbedürfnisse ins Rampenlicht, statt ihn mit Technikphrasen zu verwirren!',
      },
    ],
  },

  anchorworkshop: {
    title: 'Anchorworkshop',
    headline1:
      'IN 90 MINUTEN GEMEINSAM STRATEGIEN ERARBEITEN, UM AKTUELLE HERAUSFORDERUNGEN ZU LÖSEN.',
    keyNumbers: [
      {
        main: '+95',
        sub: 'Teilnehmer pro Jahr',
      },
      {
        main: '+10',
        sub: 'sofortige Ansätze zu Optimierungen',
      },
      {
        main: '1:1',
        sub: 'Workshop inkl. Dokumentation',
      },
      {
        main: '90Min.',
        sub: 'zur Lösung von Herausforderungen',
      },
    ],
    bookMeetingCTA: 'HIER DIREKT EINEN WORKSHOP-TERMIN BUCHEN',
    headline2: 'DER MEHRWERT DES ANCHOR WORKSHOP',
    bulletpoints: [
      {
        headline: '_KOSTENFREI',
        text: 'Der Anchor Workshop ist und bleibt kostenfrei. Als Erstgespräch nutzen wir den Workshop, damit wir unsere Partner & Kunden kennenlernen und sie einen ersten Eindruck von BLACKFLAGSHIP erhalten.Wir sehen die 90 Minuten als beidseitige Investition.',
      },
      {
        headline: '_SOFORTIGE HANDLUNGSANSÄTZE',
        text: 'In dem Workshop werden feste Prozesse angewendet, mit denen schnell Herausforderun- gen und entsprechende Lösungen identifiziert werden. Zu diesen Lösungen definieren wir gemeinsam die notwendigen Schritte für die Umsetzung.',
      },
      {
        headline: '_ZEITERSPARNIS',
        text: '„Das beste erste Kennenlernen“ - „Konkreter kann man sich nicht kennenlernen“ - Kaffeegespräche und gemeinsame Essen sind da, um Zusammenarbeiten zu vertiefen. Das erste generelle Gespräch gestalten wir gerne mit Mehrwert und effizient in 90 Minuten.',
      },
      {
        headline: '_WHITEBOARD',
        text: '„Wer das Problem in einem Satz auf Papier bringen kann, der hat bereits 50% der Lösung.“ Auf einem digitalen Whiteboard erarbeiten wir interaktiv die ersten und zweiten 50% der Lösung. Die Dokumentation steht jedem Teilnehmer zur freien Verfügung.',
      },
      {
        headline: '_ENTSCHEIDUNGEN TREFFEN',
        text: 'Unabhängig von der aktuellen Herausforderung, kann der Workshop-Teilnehmer jedes Thema mit in den Workshop bringen, vor dem er im Unternehmen steht. Im Workshop wird auf spezifische Themen eingegangen und Lösungen erarbeitet.',
      },
      {
        headline: '_UNVERBINDLICH',
        text: 'Am Ende des Workshops werden auf signifikante Schritte zur Umsetzung eingegangen. Als Teil der Lösung steht es dem Teilnehmer natürlich frei, diese intern oder mit bestehenden Partnern umzusetzen. BLACKFLAGSHIP steht dabei auch strategisch zur Seite.',
      },
    ],
    headline3: 'DER ABLAUF DES ANCHOR WORKSHOPS',
    headline4: 'DAS BESTE ERSTE KENNENLERNEN',
    getToKnow: {
      section1: {
        headline: 'DIGITALER QUICK-WORKSHOP',
        text: 'In einem 90-Minuten-Workshop erarbeiten wir gemeinsam erste individuelle Möglichkeiten zur Optimierung als Kennenlernen.',
        cta: 'HIER EINEN KOSTENFREIEN WORKSHOP-TERMIN VEREINBAREN',
      },
      section2: {
        headline: 'KURZER TELEFON-CALL',
        text: 'In einem persönlichen Gespräch beantworten wir erste Fragen, sprechen über Vorteile und die allgemeine Herangehensweise.',
        cta: 'HIER EINEN KOSTENFREIEN 15-MINUTEN-CALL VEREINBAREN',
      },
    },
  },

  anchorstrategy: {
    title: 'Anchorstrategy',
    headline1: 'BLACKFLAGSHIP ® ANCHORSTRATEGY',
    headline2: 'DAS ERSTE MARKEN-BERATUNGS-PRODUKT',
    hexItems: [
      'Festpreisgarantie unabhängig von Themen & Inhalten',
      'Ziele zeit- & budgeteffizient erreichen',
      'Umsatzoptimierung durch neue Marktpotentiale',
      'Reichweite & Sichtbarkeit durch alternative Lösungen',
    ],
    bookMeetingCTA: 'DIREKT EIN ONBOARDING VEREINBAREN',
    headline3:
      'WIE KANN EINE MASSGESCHNEIDERTE ANCHORSTRATEGY MEINE MARKE UND UNTERNEHMEN UNTERSTÜTZEN?',
    bulletpoints: [
      '_ Digitale Kommunikation, welche die Zielgruppe überzeugt',
      '_ Professionelle StatusQuo-Analyse mit Blick von aussen',
      '_ Marke spiegelt die Qualität des Unternehmens wider',
      '_ Blinde Flecken finden und beheben',
      '_ Kommunikation mit Kunden verbessern',
      '_ Potentiale identifizieren und ausrollen',
      '_ Ziele und konkrete Schritte zur Umsetzung definieren',
      '_ Ganztägiger Zielerfüllungsworkshop mit allen Beteiligten',
    ],
    quotes: [
      '„Was unser Unternehmen besonders macht, haben Kunden erst nach dem Erstgespräch erkannt. Jetzt bereits auf den ersten Blick.“',
      '„Wir haben erkannt, wie wichtig eine ganzheitlich Betreuung für unsere Kunden sein kann und binden das jetzt in alle Prozesse ein.“',
    ],
    headline4: 'ANCHORSTRATEGY - EINE STRATEGIE, DIE SOFORT UMGESETZT WERDEN KANN.',
    textBlocks: [
      {
        headline: '_ALTERNATIVE LÖSUNGEN FÜR AKTUELLE HERAUSFORDERUNGEN.',
        text: 'Aufgabe der ANCHORSTRATEGY ist, durch eine alternative Herangehensweise, schnelle und umsetzungsnahe Ansätze zu aktuellen Themen einer Brand zu kreieren. Dazu wird die Marke als individueller Teilnehmer des Marktes betrachtet. In diesem Kontext werden ebenso Wettbewerber und die Bedürfnisse von Kunden analysiert.',
      },
      {
        headline: '_POTENTIALE ENTDECKEN UND AGILER AUF DEM MARKT HANDELN.',
        text: 'Experten aus unterschiedlichen Fachbereichen analysieren und bewerten auf Basis aller Aspekte der Aussendarstellung, die Markenrelevanz eines Unternehmens. Dabei findet nicht nur eine Verfeinerung der aktuellen Kommunikation auf dem Markt statt, sondern darüber hinaus werden neue Lead- & Kundenpotentiale geschaffen.',
      },
      {
        headline: '_DIE MARKE STÄRKEN UND ZUGLEICH RESSOURCEN SCHONEN.',
        text: 'Unter den Aspekten von Konsumargumentation und Kaufentscheidungsprozessen werden neue Handlungsempfehlungen skizziert und Optimierungsmöglichkeiten geschaffen. On Top sollen mit der BLACKFLAGSHIP ® ANCHOR STRATEGY Zeit & Budget effizienter eingesetzt und der Output erhöht werden.',
      },
      {
        headline: '_STRATEGYBOOK - EIN HANDBUCH ALS LEITFADEN.',
        text: 'Auf Basis der Gesamtanalyse im Nachgang wird ein Strategybook erstellt. Neben einer Bewertung werden dort konkrete Strukturen mit Timelines, Handlungsempfehlungen, Entwicklungsmöglichkeiten, uvm. festgehalten. In einem gemeinsamen Workshop zur Zielerfüllung wird dieses Workbook veranschaulicht und diskutiert.',
      },
    ],
    onboardingCTA: 'DIREKT EIN ONBOARDING VEREINBAREN',
    headline5: 'DIE RICHTIGEN MASSNAHMEN AUS DEN RICHTIGEN GRÜNDEN ERGREIFEN.',
    headline6: 'UNSER GEMEINSAMER PROJEKTABLAUF DER ANCHORSTRATEGY',
    headline7: 'WIE KÖNNEN WIR GEMEINSAM STARTEN?',
  },

  branchesGeneric: {
    trustedBy: 'TRUSTED BY',
    goToAnchorstrategyCTA: 'WIE? ZUR BLACKFLAGSHIP ANCHORSTRATEGY',
    subheadline2Prefix: 'Wie wir u.a. ',
    subheadline2LastGlue: ' und ',
    subheadline2Suffix: ' unterstützen:',
    headline3: '_WAS MÖCHTE DEIN KUNDE WIRKLICH?',
    subheadline3:
      'WIE DU MIT UNS HERAUFORDERUNGEN LÖST, POTENTIALE NUTZT UND OPIMIERUNGEN UMSETZT.',
  },
  branches: {
    mobility: {
      keywords: ['OEM', 'Niederlassungen', 'Händler', 'Zulieferer'],
      headline1: 'Das Produkt auf allen Kanälen erlebbar machen',
      hexItems: [
        'Optimierung der Loyalisierung von Kunden',
        'Unterstützung des Vertriebs über digitale Kanäle',
        'Echte Erlebnisse mit dem Produkt schaffen',
        'Vergleichbarkeit vermeiden und USPs präsentieren',
        'Mehr B2B-Kunden gewinnen und pflegen',
      ],
      headline2: 'Mobilität',
      bulletpoints: [
        '_ Konzepte zur Produktvermarktung für B2B entwickeln und umsetzen',
        '_ Kundenerlebnisse am Produkt schaffen',
        '_ Hybrid-Events (Online + Offline) für Kunden kreieren ',
        '_ Regionalen Bezug im Handelsmarketing schaffen',
        '_ Neue Möglichkeiten im After-Sales erarbeiten',
        '_ Kunden abholen und Sales-Zyklen optimieren',
        '_ Leads gewinnen & Bedarfsananalysen online umsetzen',
        '_ Social Media für Imageaufbau und Kundengewinnung nutzen',
        '_ uvm.',
      ],
    },
    finance: {
      keywords: ['Banken', 'Berater', 'Versicherungen', 'Gold & Edelmetalle'],
      headline1: 'Sicherheit vermitteln und Vertrauen schaffen.',
      hexItems: [
        'Altes Image und Vorurteile abschaffen',
        'Kunden ein Leben lang begleiten',
        'Vorteile gegenüber Wettbewerbern vermitteln',
        'Auf Kundenbedürfnisse proaktiv eingehen',
        'Veränderungen des Marktes für sich nutzen',
      ],
      headline2: 'Finanzen',
      bulletpoints: [
        '_ Massnahmen zur Kundenbindung schaffen',
        '_ Alleinstellungsmerkmale erarbeiten und platzieren ',
        '_ Präsentationsmaterial professionalisieren',
        '_ Ablauf der Kundengespräche optimieren',
        '_ Online-Termine optimal gestalten',
        '_ Systematisierung der Kundengewinnung',
        '_ Prozesse für Kundentermine schaffen',
        '_ Konkrete Produkte schärfen und besser kommunizieren',
        '_ uvm.',
      ],
    },
    jewelry: {
      keywords: ['Hersteller', 'Manufakturen', 'Retail', 'Boutiquen'],
      headline1: 'Einzigartige Marken für einzigartige Produkte schaffen.',
      hexItems: [
        'Markenwerte stärker vermitteln',
        'Point-of-Sale der Zukunft gestalten',
        'Omnipräsenz im Gedächtnis der Kunden',
        'Luxusmarken nahbarer und erlebbar machen',
        'Nachhaltigkeit der Marken präsentieren',
      ],
      headline2: 'Schmuck & Uhren',
      bulletpoints: [
        '_ Individuelles Branding für Produktlinien aufbauen',
        '_ Massnahmen zur Vermeidung der Austauschbarkeit umsetzen',
        '_ Strategien, um Markenfans aufzubauen und zu pflegen',
        '_ DNA der Marke regional, national und international interpretieren',
        '_ Konzepte für Markenkooperationen erstellen',
        '_ Kunden & Multiplikatoren offline & online im B2B-Kontext gewinnen',
        '_ Strategien zur Erhöhung von lokaler Relevanz gemeinsam erarbeiten',
        '_ Beratung zu gesellschaftlich relevanten Themen (bspw. Nachhaltigkeit)',
        '_ uvm.',
      ],
    },
    energyinfrastructure: {
      keywords: ['Nachhaltigkeit', 'Erneuerbare Energien', 'Gehäuselösungen', 'Smart City'],
      headline1: 'Wer komplexe Inhalte einfach vermitteln kann, hat sie verstanden.',
      hexItems: [
        'Vorteile und Mehrwerte der Zusammenarbeit',
        'B2B-Kunden emotional abholen und begeistern',
        'Qualität der Beratung und Produkte präsentieren ',
        'Vergleichbarkeit über den Preis vermeiden',
        'Partnerschaftliche Zusammenarbeit fokussieren ',
      ],
      headline2: 'Energie & Infrastruktur',
      bulletpoints: [
        '_ Kommunikation auf die Bedürfnisse der Kunden umstellen',
        '_ Branding für Produkte und Produktkategorien erstellen',
        '_ Präsentation der Produkte und Dienstleistung konzipieren',
        '_ Individuelle USPs gemeinsam erarbeiten und einsetzen',
        '_ Online-Präsenzen als Produkterlebnis gestalten',
        '_ Herangehensweisen der Kundengewinnung konzipieren',
        '_ Produktinnovationen optimal beim Kunden platzieren',
        '_ Neue Kundenpotentiale erschließen und bedienen',
        '_ uvm.',
      ],
    },
    consulting: {
      keywords: ['Unternehmensberatungen', 'Personalberatung', 'Steuerberatungen', 'Versicherung'],
      headline1: 'Ein Partner und verlässlicher Spezialist mit professionellen Blick von aussen.',
      hexItems: [
        'Kunden länger betreuen und begleiten',
        'Partnerschaftliche Zusammenarbeit fokussieren',
        'Dienstleistung als erfolgreichen Prozess & Produkt präsentieren ',
        'Vergleichbarkeit der Leistung verringern',
        'Mehr Kunden durch Mehrwerte gewinnen',
      ],
      headline2: 'Consulting',
      bulletpoints: [
        '_ Konkrete Kundenbedürfnisse und Lösungen erarbeiten',
        '_ Komplexe Prozesse vereinfacht visualisieren',
        '_ Innovative Herangehensweisen in der Kundenbetreuung präsentieren',
        '_ Qualität der Unternehmenspräsentationen erhöhen ',
        '_ Optimierung der Online-Kanäle zur Vertriebsunterstützung',
        '_ Alleinstellungsmerkmale in der direkten Kommunikation platzieren',
        '_ Marktveränderungen zur Kundengewinnung nutzen und umsetzen ',
        '_ Neue Zielgruppen mit bestehendem Leistungsportfolio erschließen',
        '_ uvm.',
      ],
    },
    immobilien: {
      keywords: ['Finanzierung', 'Makler', 'Bauträger', 'Gesellschaften'],
      headline1:
        'Vertrauen schaffen und Ängste nehmen - für eine Investition in das Heute und Morgen.',
      hexItems: [
        'Marktentwicklungen direkt am Kunden vermitteln',
        'Qualität der Leistung und Beratung präsentieren',
        'Bedarfsanalysen als Teil des Erstgesprächs',
        'Gefühlte Risiken beim Kunden minimieren',
        'Starke visuelle Darstellung und Erklärung der Objekte',
      ],
      headline2: 'Immobilien',
      bulletpoints: [
        '_ Kundengewinnung im B2C beschleunigen',
        '_ Vertrauensbildende Massnahmen im Beratungsprozess implementieren',
        '_ Zusammenarbeit mit B2B-Multiplikatoren aufbauen',
        '_ Expertise kurz und prägnant formulieren',
        '_ Digitale Kundenkommunikation optimieren ',
        '_ Vorteile gegenüber Wettbewerbern darstellen',
        '_ Klare Kommunikation von Mehrwerten integrieren',
        '_ Produktvorstellung in klaren und vereinfachten Kontext setzen',
        '_ uvm.',
      ],
    },
    luxus: {
      keywords: ['Schreibgeräte', 'Audio', 'Home', 'Lifestyle'],
      headline1: 'Einzigartigkeit zeigen und Sehnsüchte schaffen',
      hexItems: [
        'Marktentwicklungen direkt am Kunden vermitteln',
        'Qualität der Leistung und Beratung präsentieren',
        'Bedarfsanalysen als Teil des Erstgesprächs',
        'Gefühlte Risiken beim Kunden minimieren',
        'Objekte angemessen darstellen und präsentieren',
      ],
      headline2: 'Luxusgüter',
      bulletpoints: [
        '_ Sichtbarkeit bei der Zielgruppe erhöhen',
        '_ Lokale und regionale Sichtbarkeit der Marke ',
        '_ Aus dem Point of Sale einen Point of Experience schaffen',
        '_ Markenstärke im Vertrieb umsetzen',
        '_ Marke zeitgemäß re-definieren',
        '_ Kundenkreise verjüngen',
        '_ Tradition der Marke in den aktuellen Kontext setzen',
        '_ Kontakthürden beim Kunden minimieren und fördern',
        '_ uvm.',
      ],
    },
    industry: {
      keywords: ['Telekommunikation', 'Druck', 'Medizin', 'Bau'],
      headline1: 'Wenn die Qualität der Marke die Qualität des Produktes widerspiegelt',
      hexItems: [
        'Individuelle Produktentwicklung mit Kunden zeigen',
        'Qualität der Produkte und Beratung besser präsentieren ',
        'Sales-Optimierung durch Bedarfsanalysen statt Produktverkauf ',
        'Preisvergleich vermeiden und Kunden binden',
        'Gefühlte Risiken beim Kunden minimieren',
      ],
      headline2: 'Industrie',
      bulletpoints: [
        '_ Emotionale B2B-Marke etablieren',
        '_ Mehrwert und Vorteile für Kunden klar kommunizieren',
        '_ Produkte und Leistungen vereinfacht präsentieren',
        '_ Kunden im Gesamtprojekt besser beraten und unterstützen',
        '_ Beständigkeit und Anspruch im Kundengedächtnis etablieren',
        '_ Innovationen optimal im Markt präsentieren',
        '_ Produktanwendungen für den Markt greifbar machen',
        '_ Vorteile für den Kunden des Kunden erarbeiten',
        '_ uvm.',
      ],
    },
    foodbeverages: {
      keywords: ['Speisen', 'Spirituosen', 'Wein', 'Energy'],
      headline1: 'Geschmack visualisieren und Nischen besetzen',
      hexItems: [
        'Produkt einzigartig in Szene setzen',
        'Angemessene Preise für Produktqualität erzielen',
        'Wiederkehrende Kunden für sich gewinnen',
        'Echte Nachhaltigkeit für Mensch und Umwelt',
        'Markenfans auf den sozialen Netzwerken gewinnen',
      ],
      headline2: 'Food & Beverages',
      bulletpoints: [
        '_ Emotionale Bindung an die Marke schaffen',
        '_ Produktvorteile präsentieren',
        '_ Soziale, ökonomische und ökologische Nachhaltigkeit präsentieren',
        '_ Preiskampf vermeiden und Produktqualität fokussieren ',
        '_ Informationen beim Kunden verständlich vermitteln',
        '_ Vergleichbarkeit vermeiden',
        '_ Neue Zielgruppen definieren und langfristig binden',
        '_ Zielführende Online-Massnahmen erstellen',
        '_ uvm.',
      ],
    },
  },

  whoWeAre: {
    title: 'Who we are',
    headline1: 'BLACKFLAGSHIP - KEINE MARKETINGAGENTUR, KEINE WERBEAGENTUR - EINE MARKENAGENTUR',
    headline2: 'EIN BLICK VON AUSSEN - STIMMEN UNSERER KUNDEN & PARTNER',
    slides: [
      {
        person: 'Robert Engel',
        position: 'Vorstand - Hilpert AG',
        text: '„Wir sind fasziniert, wie gut ihr mit eurer Analyse unsere DNA erkannt habt. Wir haben das Gefühl, ihr seid ein Teil von uns und versteht genau wo wir hin wollen. Das Lebensphasenkonzept, welches ihr entwickelt habt ist Bombe!! Wir freuen uns mit euch den Weg weiter zu gehen und unsere Marke weiter zu schärfen.“',
      },
      {
        person: 'Susanne Pehse',
        position: 'Veranstaltungen-Marketing - Audi Zentrum Dresden',
        text: '„Die zwei „men in black‘, Milan und Manu strahlen Sicherheit, Vertrauen & Kraft aus. Sie hören gut zu, gehen schnell in die Umsetzung und überraschen dann mit einem authentischen Konzept in welchem sich die gemeinsam erarbeiteten Visionen & Ideen wiederfinden. 5 Sterne für das Team „men in black“ aus Berlin, die anders als die Filmhelden nicht mit großen Waffen sondern mit gut durchdachten Konzepten ihr Ziel treffen.“',
      },
      {
        person: 'Markus Wellhausen',
        position: 'Leiter Marketing Kommunikation und PR - BMW Niederlassung Berlin',
        text: '„Tolles, kreatives und zuverlässiges Team. Die Zusammenarbeit und Projektumsetzung mit BFS macht jedes Mal aufs Neue sehr viel Spaß. Jedes Event ist ein besonderes Highlight. Wir freuen uns auf mehr!“',
      },
      {
        person: 'Patric Haß',
        position: 'Marketing & Kommunikation - SEAT Zentrum Berlin',
        text: '„Die Zusammenarbeit mit Blackflagship ist für uns die perfekte Ergänzung. Die gesamte Agentur hat sich unglaublich schnell mit unseren Marken auseinandergesetzt und identifiziert. Danke für eure klasse Ideen und für eure unglaubliche Flexibilität!“',
      },
      {
        person: 'Tim Haag',
        position: 'Geschäftsführer - Think Internet GmbH',
        text: '„Wir setzen gemeinsam mit der BFS underschiedlichste Webprojekte um, dabei ist es essenziell eine schnelle, zuverflässige und kompetente Kommunikation zu halten und darauf können wir uns zu 100 % bei dem gesamten BFS-Team verlassen. Die Ansprechpartner für die jeweiligen Bereiche sind super kompetent und wir freuen uns schon auf das nächste gemeinsame Projekt!“',
      },
      {
        person: 'Manfred Steinkellner',
        position: 'Executive Partner - Steinkellner Global Search',
        text: 'Wir schätzen das tiefe Marketingverständnis und menschliche Herangehensweise. Außerdem zeichnet Emanuel aus, die Situation individuell im Detail zu betrachten, was besonderes für uns extrem wichtig ist. Mit dieser Basis hat er unser Unternehmen verstanden und so in allen Punkten richtig beraten. Er sieht sich ebenso als strategischer Entwicklungspartner für das Unternehmen und bedient damit eine 2. Ebenen. Diese Kombination macht BLACKFLAGSHIP zu einem Unternehmen, dass ich sehr gerne weiterempfehlen kann.',
      },
    ],
    headline3: 'WIR SETZEN KEINE MODELLE UM - WIR SETZEN DEIN UNTERNEHMEN UM.',
    characteristics: [
      {
        headline: 'KEIN BAUCHLADEN-PRINZIP',
        text: 'BLACKFLAGSHIP bietet seinen Kunden das an, was sie zur Ergänzung für ihren Erfolg benötigen. Es werden keine Produkte verkauft, die die „Lösung aller Probleme“ sind.',
      },
      {
        headline: 'KEIN 08/15',
        text: 'Trends erkennen und entscheiden, diese zu nutzen oder nicht. Unter der Massgabe, die Alleinstellungsmerkmale unserer Kunden in unserer Arbeit einzusetzen.',
      },
      {
        headline: 'KEIN „BLINDES UMSETZEN“',
        text: 'Wir hinterfragen gerne und oft um, das Richtige aus den richtigen Gründen zu tun. „Mitdenken“ und Dinge besser machen, statt blindes Umsetzen.',
      },
      {
        headline: 'KEIN MARKETING-BULLSHIT',
        text: '„Wer es wirklich verstanden hat, kann es einfach erklären.Wir nutzen unsere Expertise für Klartext, statt uns hinter Fachchinesisch zu verstecken.',
      },
      {
        headline: 'KEIN „COPY & PASTE“',
        text: 'Individuelle Unternehmen & Marken in verschiedenen Branchen erfordern eine eigene Betrachtung, Betreuung und Umsetzung.',
      },
    ],
    headline4: 'WIE WIR UNS SELBST SEHEN & WAS BLACKFLAGSHIP IM KERN AUSMACHT',
    hexItems: [
      'Freie, eigenfinanzierte Agentur in der ersten Generation.',
      'Perspektivenwechsel für gesellschaftlich relevante Themen.',
      'Verantwortungsvoller und getreuer Umgang mit Budgets und Etats.',
      'Strategische Vorge- hensweise und umset- zungsnahe Methodik.',
      'Taktische Spezialein- heit in zwei Welten - Marke & Retail.',
      'Global Brands und Hidden Stars im Kundenportfolio.',
      'Visuell einzigartige Kunden- & Konzept- präsentationen.',
      'Ganzheitliches Qualitäts- verständnis und hoher eigener Anspruch.',
      'Begeisterte Kunden durch fokussierte und unkonven- tionelle Arbeitsweise.',
    ],
    meetingCTA: 'ZUM BESTEN „ERSTEN KENNENLERNEN“',
  },

  whatWeDo: {
    title: 'What we do',
    headline1: 'DIE RICHTIGEN MASSNAHMEN AUS DEN RICHTIGEN GRÜNDEN ERGREIFEN.',
    hexItems: [
      'Fokus auf den Kunden und die Zielgruppe',
      'Beratung und Optimierung von Prozessen',
      'Wiedererkennung schaffen',
      'Marktvorteile erkennen und nutzen',
    ],
    headline2: 'DAS „WARUM“ - UNSERE MISSION',
    missionText1:
      'Das Richtige aus den richtigen Gründen tun. Das erarbeiten wir in einem vorgeschalten Konzept, auch um Umsetzung und Kostenaufwand zu optimieren. Durch stimmige Platzierung im Markt erzielen wir Relevanz und Sichtbarkeit, generieren Alleinstellungsmerkmale, planen Ziele und kommunizieren Markenimage. Wir steuern Marketing- & PR-Maßnahmen und präsentieren die Marke in bestem Licht.',
    missionText2: 'Unser Ziel für Dich:',
    missionText3: 'Die richtige Strategie entwicklen, die im Unternehmen auch umsetzbar ist.',
    strategyCTA: 'ZU UNSEREM STRATEGIE-FUNDAMENT',
    quickSections: [
      {
        headline: 'STRATEGY &<br/>IDENTITY',
        text: 'Jedes Unternehmen ist anders – so individuell sind die Methoden, mit denen Markenpositionierung und Strategie durchzuführen sind. Durch stimmige Platzierung im Markt erzielen wir die optimale Brand Awarness.',
        link: '',
      },
      {
        headline: 'DESIGN &<br/>EXPERIENCE',
        text: 'Mit richtigem Design überschreitet man Grenzen pragmatischer grafischer Abbildung und Vermittlung von Emotionen. Die Union aus Ästhetik und Markenbotschaft untermauert die Präsentation Deiner Brand.',
        link: '',
      },
      {
        headline: 'CONSULTING &<br/>INTEGRATION',
        text: 'BLACKFLAGSHIP unterstützt Dich dabei, die Marktbedürfnisse und Herausforderungen an Deiner Marke zu identifizieren und umzusetzen. Strategische Platzierung, Re-Branding oder eine Überarbeitung des bestehenden Images.',
        link: '',
      },
    ],
    quickSectionsCTA: 'MEHR',
    headline3: 'STRATEGY // IDENTITY',
    strategyIdentityItems: [
      {
        headline: 'ALLEINSTELLUNG',
        text: 'Jedes Unternehmen hat einen USP. Damit das für deine Kunden keine leeren Phrasen sind, erarbeiten wir gemeinsam echte Alleinstellungsmerkmale als Teil der Unternehmens-DNA und verdeutlichen deine Mission in einem Satz.',
      },
      {
        headline: 'KONZEPTION',
        text: 'Effiziente und erfolgreiche Projekte beginnen mit einem individuellen Konzept. Ausgehend vom Status Quo entwickeln wir stimmige Herangehensweisen für die Lösung von Herausforderungen und ein langfristiges Fundament für Optimierungen.',
      },
      {
        headline: 'MARKENSTRATEGIE',
        text: 'Ganz individuell erarbeiten wir eine zu dir und deiner Marke passende Strategie. Im Fokus steht dabei die Einzigartigkeit deiner Marke. Wir rücken sie ins rechte Licht, positionieren sie passend am Markt und grenzen sie von Wettbewerbern ab.',
      },
      {
        headline: 'KUNDENFOKUS',
        text: 'Deine Kunden und deren Bedürfnisse stehen im Mittelpunkt unserer Arbeit. Wir gewinnen das Wissen um die Wünsche und erarbeiten Konsumargumentationen. Aus der Customer Centricity resultieren Herangehensweisen auf allen Kommunikationskanälen und Medien.',
      },
    ],
    headline4: 'DESIGN // EXPERIENCE',
    designExperienceItems: [
      {
        headline: 'BRAND DESIGN',
        text: 'Der Charakter deiner Marke wird in einem individuellen Design erfasst, welches weit über eine pragmatische Abbildung hinausgeht. Wir verbinden funktionale Aspekte mit Ästhetik und bringen deine Markenbotschaft in einer einzigartigen Inszenierung auf den Punkt.',
      },
      {
        headline: 'LIVE & POS',
        text: 'Wir sind da, wo deine Kunden sind. Und genau da, wo deine Kunden ihre Kaufentscheidungen treffen, positionieren wir alle perfekt abgestimmten Marketingmaßnahmen und schaffen aus einem Point of Sale einen Point of Experience.',
      },
      {
        headline: 'SOCIAL MEDIA',
        text: 'Dort sein, wo sich der Kunde bewegt. Ob eine aktive Akquise über die sozialen Netzwerke geschaffen werden soll oder Kunden sich vom Unternehmen überzeugen sollen - wir setzen deine Marke via Social Media branchenübergreifend um.',
      },
      {
        headline: 'WEB & ONLINE',
        text: 'Eine professionelle Webpräsenz ist unverzichtbar. Unser Team kreiert für dich einen einzigartigen und nutzerfreundlichen Onlineauftritt, der die Identität deiner Marke verkörpert und dem Kunden die richtigen Informationen vermittelt.',
      },
    ],
    headline5: 'CONSULTUNG // INTEGRATION',
    consultingIntegrationItems: [
      {
        headline: 'WORKSHOPS',
        text: 'Moderation, Leitung und aktive Zusammenarbeit. Wir nutzen Workshops, um Herausforderungen zu lösen, neue Wege zu entdecken und gemeinsam Potentiale umsetzbar zu machen. So werden Themen fokussiert, die sich nachhaltig im Unternehmen etablieren lassen.',
      },
      {
        headline: 'PROZESSE',
        text: 'Wir schaffen gemeinsam Prozesse für die Customer Journey, Marketing & Sales. Diese Prozesse optimieren tägliche und operative Abläufe. Dazu nutzen wir Tools und Expertisen der langjährigen Kundenarbeit.',
      },
      {
        headline: 'SALESKONZEPT',
        text: 'Für dich und mit dir erstellen wir ein genau auf deine Marke zugeschnittenes Saleskonzept und alle notwendigen Unterlagen offline & online. Ziel ist, dass Marketing- & Vertriebsaktivitäten Hand in Hand gehen und Synergien erzeugt werden.',
      },
      {
        headline: 'UMSETZUNG',
        text: 'Unabhängig von der Thematik, bleiben wir nicht bei der Theorie, sondern gehen in die Umsetzung. Gemeinsam setzen wir die zuvor erarbeiteten Strategien in die Praxis um und richten diese konstant an aktuellen Entwicklungen aus.',
      },
    ],
  },

  header: {
    nav: {
      items: [
        { link: '/', name: 'Home' },
        { link: 'what-we-do', name: 'What we do' },
        { link: 'who-we-are', name: 'Who we are' },
        { link: 'anchorstrategy', name: 'Anchorstrategy' },
        { link: 'anchorworkshop', name: 'Anchorworkshop' },
        { link: 'blackpaper', name: 'Blackpaper' },
        { link: 'businessnavigator', name: 'Businessnavigator' },
        { link: 'contact', name: 'Kontakt' },
        { link: 'impressum', name: 'Impressum' },
        { link: 'datenschutz', name: 'Datenschutz' },
      ],
    },
  },

  imprint: {
    headline: 'Impressum',
  },

  dataProtection: {
    headline: 'Datenschutz',
  },

  general: {
    address: 'Alt-Tempelhof 37, 12103 Berlin',
    phone: '+49 (0) 30 75 65 37 92',
    mail: 'info@blackflagship.com',
    homepage: 'www.blackflagship.com',
  },
}

module.exports = {
  translation,
  langKey: 'DE',
}
