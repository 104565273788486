import "./Footer.scss";
import { useSelector } from "react-redux";
import CustomContainer from "../../prebuild-sections/CustomContainer/CustomContainer";

const Footer = () => {
  const translation = useSelector((s) => s.translation);

  return (
    <div id="footer">
      <CustomContainer>
        <div className="split">
          <div className="contact">
            <div>{translation.general.address}</div>
            <div>
              <a href={`tel:${translation.general.phone}`}>
                {translation.general.phone}
              </a>
            </div>
            <div>
              <a href={`mailto:${translation.general.mail}`}>
                {translation.general.mail}
              </a>
            </div>
            <div>{translation.general.homepage}</div>
          </div>
          <div className="copyright">
            <span className="bold">BLACK</span>FLAGSHIP&copy;
            {new Date().getFullYear()}
          </div>
        </div>
      </CustomContainer>
    </div>
  );
};
export default Footer;
