import { useMediaQuery } from "@mui/material";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { setResponsiveState } from "../../../reducer/action/responsiveState";
import { maxMobileWidth } from "../../../utility";

const ResponsiveState = () => {
  const dispatch = useDispatch();

  const isMobile = useMediaQuery(`(max-width:${maxMobileWidth}px)`);

  useEffect(() => {
    dispatch(setResponsiveState({ isMobile }));
  }, [isMobile, dispatch]);

  return <></>;
};

export default ResponsiveState;
