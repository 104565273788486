import ShowIfVisible from '../../animations/ShowIfVisible'
import slideUp from '../../animations/slideUp'
import './Bulletpoint.scss'

const Bulletpoint = ({ bulletpoint, index }) => {
  const { headline, text } = bulletpoint
  return (
    <div className="anchorworkshop-bulletpoint">
      <ShowIfVisible animation={slideUp} duration={`${index + 0.5}s`}>
        <div className="headline">{headline}</div>
        <div className="text">{text}</div>
      </ShowIfVisible>
    </div>
  )
}

export default Bulletpoint
