import Layout from '../Layout/Layout'
import './Home.scss'
import heroImage from '../../assets/image/home/hero.png'
import sharingHeroImage from '../../assets/image/home/sharing-hero.png'
import multihexagon from '../../assets/image/home/multihexagon.png'
import wheelIcon from '../../assets/image/wheel.png'
import Headline from '../prebuild-sections/Headline/Headline'
import CustomContainer from '../prebuild-sections/CustomContainer/CustomContainer'
import { useSelector } from 'react-redux'
import Separator from '../prebuild-sections/Separator/Separator'
import { getUUID } from '../../utility'
import foodbaverages from '../../assets/image/branches/foodbaverages/hero.png'
import consulting from '../../assets/image/branches/consulting/hero.png'
import energyInfrastructure from '../../assets/image/branches/energyInfrastructure/hero.png'
import finance from '../../assets/image/branches/finance/hero.png'
import immobilien from '../../assets/image/branches/immobilien/hero.png'
import industry from '../../assets/image/branches/industry/hero.png'
import jewelry from '../../assets/image/branches/jewelry/hero.png'
import luxus from '../../assets/image/branches/luxus/hero.png'
import mobility from '../../assets/image/branches/mobility/hero.png'
import symbol from '../../assets/image/home/symbol.png'
import lupe from '../../assets/image/home/lupe.png'
import compass from '../../assets/image/compass.png'
import { useEffect, useState } from 'react'
import Slider from '../WhoWeAre/Slider/Slider'
import rolls from '../../assets/image/home/rolls.png'
import sennheiser from '../../assets/image/home/sennheiser.png'
import maserati from '../../assets/image/home/maserati.png'
import montblanc from '../../assets/image/home/montblanc.png'
import bmw from '../../assets/image/home/bmw.png'
import degussa from '../../assets/image/home/degussa.png'
import audi from '../../assets/image/home/audi.png'
import fca from '../../assets/image/home/fca.png'
import boconcept from '../../assets/image/home/boconcept.png'
import chopard from '../../assets/image/home/chopard.png'
import hublot from '../../assets/image/home/hublot.png'
import infinity from '../../assets/image/home/infinity.png'
import seat from '../../assets/image/home/seat.png'
import mini from '../../assets/image/home/mini.png'
import black28 from '../../assets/image/home/28black_logo.png'
import renault from '../../assets/image/home/renault.png'
import anchorLogo from '../../assets/image/home/anchor-logo.svg'
import MetaTags from '../prebuild-sections/MetaTags/MetaTags'
import bgOverlay1 from '../../assets/image/home/bg-overlap-1.png'
import bgOverlay2 from '../../assets/image/home/bg-overlap-2.png'
import ShowIfVisible from '../animations/ShowIfVisible'
import slideLeftToRight from '../animations/slideLeftToRight'
import slideRightToLeft from '../animations/slideRightToLeft'
import slideUp from '../animations/slideUp'

const Home = () => {
  const translation = useSelector((s) => s.translation)
  const [selectedBranchIndex, setSelectedBranchIndex] = useState(0)
  const [branches, setBranches] = useState(null)
  const porfolioImages = [
    rolls,
    sennheiser,
    maserati,
    montblanc,
    bmw,
    degussa,
    audi,
    fca,
    boconcept,
    chopard,
    hublot,
    infinity,
    seat,
    mini,
    black28,
    renault,
  ]

  useEffect(() => {
    if (!branches) {
      const branchImages = [
        mobility,
        finance,
        jewelry,
        energyInfrastructure,
        consulting,
        immobilien,
        luxus,
        industry,
        foodbaverages,
      ]
      const branches = Object.keys(translation.branches).map((key, i) => {
        return {
          key,
          headline: translation.branches[key].headline2,
          image: branchImages[i],
          keywords: translation.branches[key].keywords,
        }
      })
      setBranches(branches)
    }
    let branchSelectionInverval = null
    if (branches) {
      branchSelectionInverval = setInterval(() => {
        if (selectedBranchIndex + 1 >= branches.length) {
          setSelectedBranchIndex(0)
        } else {
          setSelectedBranchIndex(selectedBranchIndex + 1)
        }
      }, 2500)
    }
    return () => clearInterval(branchSelectionInverval)
  }, [branches, translation, selectedBranchIndex])

  const selectManually = (i) => () => {
    setSelectedBranchIndex(i)
  }

  return (
    <Layout noLogo={true}>
      <MetaTags
        title={translation.home.title}
        description={translation.home.headline1}
        image={sharingHeroImage}
      />
      <div id="home">
        <div className="hero" style={{ backgroundImage: `url(${heroImage})` }}>
          <ShowIfVisible animation={slideUp}>
            <div className="main-logo">
              <img src={anchorLogo} alt={translation.project.name} />
            </div>
          </ShowIfVisible>
          <div className="trusted">
            <div className="label">{translation.branchesGeneric.trustedBy}</div>
            <div className="logos">
              <div className="logo">
                <img src={degussa} alt="" />
              </div>
              <div className="logo">
                <img src={montblanc} alt="" />
              </div>
              <div className="logo">
                <img src={maserati} alt="" />
              </div>
              <div className="logo">
                <img src={hublot} alt="" />
              </div>
              <div className="logo">
                <img src={chopard} alt="" />
              </div>
            </div>
          </div>
          <div className="separator-wrapper">
            <Separator />
          </div>
        </div>
        <CustomContainer>
          <div className="section-sympol-wrapper">
            <img src={wheelIcon} alt="wheel" />
          </div>
          <div className="split">
            <ShowIfVisible animation={slideLeftToRight}>
              <div className="item">
                <Headline>{translation.home.headline1}</Headline>
                <div className="text">
                  <span className="bold">BLACK</span>FLAGSHIP - die Markenagentur für erfolgreichen
                  Marken-Aufbau, spezifische Marken-Kommunikation & adäquate Marken-Positionierung.
                </div>
              </div>
            </ShowIfVisible>
            <ShowIfVisible animation={slideRightToLeft}>
              <div className="item img">
                <img src={multihexagon} alt="" />
              </div>
            </ShowIfVisible>
          </div>
          <div className="cta-wrapper">
            <a href="/what-we-do" className="cta">
              {translation.home.whyCTA}
            </a>
          </div>
          <Separator />
          {branches && (
            <div className="split">
              <div className="item">
                <Headline>{translation.home.headline2}</Headline>
                <div className="branches">
                  {branches.map(({ key, headline }, i) => {
                    return (
                      <div
                        className={`branch${selectedBranchIndex === i ? ' selected' : ''}`}
                        onClick={() => setSelectedBranchIndex(i)}
                        onMouseEnter={selectManually(i)}
                        key={getUUID()}
                      >
                        <a href={`/${key}`}>{headline}</a>
                      </div>
                    )
                  })}
                  <div className="branch">
                    <a href="/what-we-do">{translation.home.branchesMore}</a>
                  </div>
                </div>
              </div>
              <div className="item">
                <div className="selected-branch">
                  <div className="thumbnail">
                    <a href={`/${branches[selectedBranchIndex].key}`}>
                      <img src={branches[selectedBranchIndex].image} alt="" />
                    </a>
                  </div>
                  <div className="characteristics">
                    {branches[selectedBranchIndex].keywords.map((char) => (
                      <a
                        href={`/${branches[selectedBranchIndex].key}`}
                        className="char"
                        key={getUUID()}
                      >
                        {char}
                      </a>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          )}
          <Separator />
          <div className="section-sympol-wrapper">
            <img src={compass} alt="compass" />
          </div>
          <div className="statements">
            <Headline>
              <div className="headline-content">{translation.home.headline3}</div>
            </Headline>
            <Slider />
          </div>
          <Separator />
          <div className="section-sympol-wrapper">
            <img src={symbol} alt="symbol" />
          </div>
          <CustomContainer minimized>
            <div className="overlap-section left">
              <div className="text">
                <ShowIfVisible animation={slideLeftToRight}>
                  <Headline>{translation.home.headline4}</Headline>
                  <div className="subheadline">{translation.home.subheadline4}</div>
                </ShowIfVisible>
                <div className="image-on-mobile-wrapper">
                  <img src={bgOverlay1} alt="strategy-book" />
                </div>
              </div>
              <div className="cta-wrapper">
                <a href="/anchorstrategy" className="cta">
                  {translation.home.cta4}
                </a>
              </div>
            </div>
          </CustomContainer>
          <Separator />
          <div className="custom-overflow-headline">{translation.home.customOverflowHeadline}</div>
          <CustomContainer minimized>
            <div className="overlap-section right">
              <div className="text">
                <ShowIfVisible animation={slideRightToLeft}>
                  <Headline>{translation.home.headline5}</Headline>
                </ShowIfVisible>
                <div className="image-on-mobile-wrapper">
                  <img src={bgOverlay2} alt="strategy-book" />
                </div>
              </div>
              <div className="cta-wrapper">
                <a href="/what-we-do" className="cta">
                  {translation.home.cta5}
                </a>
              </div>
            </div>
          </CustomContainer>
          <Separator />
          <div className="section-sympol-wrapper">
            <img src={lupe} alt="lupe" />
          </div>
          <Headline>{translation.home.headline6}</Headline>
          <div className="portfolio">
            {porfolioImages.map((img) => (
              <div className="item" key={getUUID()}>
                <img src={img} alt="" />
              </div>
            ))}
          </div>
        </CustomContainer>
      </div>
    </Layout>
  )
}

export default Home
