import { useSelector } from 'react-redux'
import './QuickSection.scss'
import compass from '../../../assets/image/compass.png'
import ring from '../../../assets/image/what-we-do/ring.png'
import wheel from '../../../assets/image/wheel.png'
import { getUUID } from '../../../utility'

const QuickSection = ({ setActiveSectionIndex }) => {
  const translation = useSelector((s) => s.translation)

  const images = [compass, ring, wheel]

  return (
    <div className="what-we-do-quick-section">
      {translation.whatWeDo.quickSections.map((item, i) => (
        <div className="item" key={getUUID()}>
          <div className="img">
            <img src={images[i]} alt={item.headline} />
          </div>
          <div className="headline" dangerouslySetInnerHTML={{ __html: item.headline }}></div>
          <div className="description">{item.text}</div>
          <span className="cta" onClick={() => setActiveSectionIndex(i)}>
            {translation.whatWeDo.quickSectionsCTA}
          </span>
        </div>
      ))}
    </div>
  )
}
export default QuickSection
