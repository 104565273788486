import Template from './Template/Template'
import hero from '../../assets/image/branches/mobility/hero.png'

// trausted brand images
import alpharomeo_logo from '../../assets/image/branches/mobility/logos/alpharomeo_logo.png'
import audi_logo from '../../assets/image/branches/mobility/logos/audi_logo.png'
import bmw_logo from '../../assets/image/branches/mobility/logos/bmw_logo.png'
import infiniti_logo from '../../assets/image/branches/mobility/logos/infiniti_logo.png'
import maserati_logo from '../../assets/image/branches/mobility/logos/maserati_logo.png'
import mini_logo from '../../assets/image/branches/mobility/logos/mini_logo.png'
import nissan_logo from '../../assets/image/branches/mobility/logos/nissan_logo.png'
import renault_logo from '../../assets/image/branches/mobility/logos/renault_logo.png'
import rolls_roys_logo from '../../assets/image/branches/mobility/logos/rolls_roys_logo.png'
import seat_logo from '../../assets/image/branches/mobility/logos/seat_logo.png'

// bulletpoint images
import blackflagship_markenagentur_0015_auto_14 from '../../assets/image/branches/mobility/gallery/blackflagship_markenagentur_0015_auto_14.png'
import blackflagship_markenagentur_0016_auto_13 from '../../assets/image/branches/mobility/gallery/blackflagship_markenagentur_0016_auto_13.png'
import blackflagship_markenagentur_0017_auto_12 from '../../assets/image/branches/mobility/gallery/blackflagship_markenagentur_0017_auto_12.png'
import blackflagship_markenagentur_0018_auto_11 from '../../assets/image/branches/mobility/gallery/blackflagship_markenagentur_0018_auto_11.png'
import blackflagship_markenagentur_0019_auto_10 from '../../assets/image/branches/mobility/gallery/blackflagship_markenagentur_0019_auto_10.png'
import blackflagship_markenagentur_0020_auto_9 from '../../assets/image/branches/mobility/gallery/blackflagship_markenagentur_0020_auto_9.png'
import blackflagship_markenagentur_0021_auto_8 from '../../assets/image/branches/mobility/gallery/blackflagship_markenagentur_0021_auto_8.png'
import blackflagship_markenagentur_0022_auto_7 from '../../assets/image/branches/mobility/gallery/blackflagship_markenagentur_0022_auto_7.png'
import blackflagship_markenagentur_0023_auto_6 from '../../assets/image/branches/mobility/gallery/blackflagship_markenagentur_0023_auto_6.png'
import blackflagship_markenagentur_0024_auto_5 from '../../assets/image/branches/mobility/gallery/blackflagship_markenagentur_0024_auto_5.png'
import blackflagship_markenagentur_0025_auto_4 from '../../assets/image/branches/mobility/gallery/blackflagship_markenagentur_0025_auto_4.png'
import blackflagship_markenagentur_0026_auto_3 from '../../assets/image/branches/mobility/gallery/blackflagship_markenagentur_0026_auto_3.png'
import blackflagship_markenagentur_0027_auto_2 from '../../assets/image/branches/mobility/gallery/blackflagship_markenagentur_0027_auto_2.png'
import blackflagship_markenagentur_0028_auto_1 from '../../assets/image/branches/mobility/gallery/blackflagship_markenagentur_0028_auto_1.png'

import hexSVG1 from '../../assets/image/branches/mobility/svgs/Mobilität_1.svg'
import hexSVG2 from '../../assets/image/branches/mobility/svgs/Mobilität_2.svg'
import hexSVG3 from '../../assets/image/branches/mobility/svgs/Mobilität_3.svg'
import hexSVG4 from '../../assets/image/branches/mobility/svgs/Mobilität_4.svg'
import hexSVG5 from '../../assets/image/branches/mobility/svgs/Mobilität_5.svg'

const Mobility = () => {
  const trustedBrandImages = [
    alpharomeo_logo,
    audi_logo,
    bmw_logo,
    infiniti_logo,
    maserati_logo,
    mini_logo,
    nissan_logo,
    renault_logo,
    rolls_roys_logo,
    seat_logo,
  ]
  const bulletpointImages = [
    blackflagship_markenagentur_0015_auto_14,
    blackflagship_markenagentur_0016_auto_13,
    blackflagship_markenagentur_0017_auto_12,
    blackflagship_markenagentur_0018_auto_11,
    blackflagship_markenagentur_0019_auto_10,
    blackflagship_markenagentur_0020_auto_9,
    blackflagship_markenagentur_0021_auto_8,
    blackflagship_markenagentur_0022_auto_7,
    blackflagship_markenagentur_0023_auto_6,
    blackflagship_markenagentur_0024_auto_5,
    blackflagship_markenagentur_0025_auto_4,
    blackflagship_markenagentur_0026_auto_3,
    blackflagship_markenagentur_0027_auto_2,
    blackflagship_markenagentur_0028_auto_1,
  ]

  const hexSvgs = [hexSVG1, hexSVG2, hexSVG3, hexSVG4, hexSVG5]

  return (
    <Template
      translationKeyword={'mobility'}
      hero={hero}
      svgs={hexSvgs}
      trustedBrandImages={trustedBrandImages}
      bulletpointImages={bulletpointImages}
    />
  )
}

export default Mobility
