const de = require("../shared-resources/translations/de");
const settings = require("../shared-resources/settings/settings");

const defaultState = {
  translation: de.translation,
  currentLang: de.langKey,
  settings,
};

export default defaultState;
