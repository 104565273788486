import './Anchorstrategy.scss'
import Layout from '../Layout/Layout'
import heroImage from '../../assets/image/anchorstrategy/hero.png'
import Hero from '../prebuild-sections/Hero/Hero'
import Separator from '../prebuild-sections/Separator/Separator'
import Headline from '../prebuild-sections/Headline/Headline'
import { useSelector } from 'react-redux'
import CustomContainer from '../prebuild-sections/CustomContainer/CustomContainer'
import { getUUID } from '../../utility'
import HexItem from './HexItem/HexItem'
import graph from '../../assets/image/anchorstrategy/graph.png'
import multiHexagonGraphic from '../../assets/image/anchorstrategy/multiHexagonGraphic.png'
import timeline from '../../assets/image/anchorstrategy/timeline.png'
import GetToKnow from '../prebuild-sections/GetToKnow/GetToKnow'
import MetaTags from '../prebuild-sections/MetaTags/MetaTags'
import HexSVG81 from '../../assets/image/anchorstrategy/Element 81.svg'
import HexSVG82 from '../../assets/image/anchorstrategy/Element 82.svg'
import HexSVG83 from '../../assets/image/anchorstrategy/Element 83.svg'
import HexSVG84 from '../../assets/image/anchorstrategy/Element 84.svg'
import ShowIfVisible from '../animations/ShowIfVisible'
import slideUp from '../animations/slideUp'

const Anchorstrategy = () => {
  const translation = useSelector((s) => s.translation)

  return (
    <Layout>
      <MetaTags
        title={translation.anchorstrategy.title}
        description={translation.anchorstrategy.headline3}
        image={heroImage}
      />
      <div id="anchorstrategy">
        <Hero img={heroImage} />
        <CustomContainer>
          <Headline>{translation.anchorstrategy.headline1}</Headline>
          <Headline>{translation.anchorstrategy.headline2}</Headline>
          <ShowIfVisible animation={slideUp}>
            <div className="hex-items">
              <HexItem svg={HexSVG81} />
              <HexItem svg={HexSVG82} isOdd />
              <HexItem svg={HexSVG83} />
              <HexItem svg={HexSVG84} isOdd />
            </div>
          </ShowIfVisible>
          <div className="book-meeting-cta-wrapper">
            <a
              href="https://go.oncehub.com/MeetingEG"
              target="_blank"
              rel="noreferrer"
              className="book-meeting-cta"
            >
              {translation.anchorstrategy.bookMeetingCTA}
            </a>
          </div>
          <Separator />
          <Headline>
            <div className="headline-content">{translation.anchorstrategy.headline3}</div>
          </Headline>
          <div className="graph-section">
            <div className="bulletpoints-and-quotes">
              <div className="bulletpoints">
                {translation.anchorstrategy.bulletpoints.map((bulletpoint) => (
                  <div className="bulletpoint" key={getUUID()}>
                    {bulletpoint}
                  </div>
                ))}
              </div>
              <div className="quotes">
                {translation.anchorstrategy.quotes.map((quote) => (
                  <div className="quote" key={getUUID()}>
                    {quote}
                  </div>
                ))}
              </div>
            </div>
            <div className="graph">
              <img src={graph} alt="graph" />
            </div>
          </div>
          <Separator />
          <Headline>
            <div className="headline-content">{translation.anchorstrategy.headline4}</div>
          </Headline>
          <div className="text-blocks">
            {translation.anchorstrategy.textBlocks.map((block, i) => (
              <div className="block" key={getUUID()}>
                <ShowIfVisible animation={slideUp} duration={`${i + 1}s`}>
                  <div className="headline">{block.headline}</div>
                  <div className="text">{block.text}</div>
                </ShowIfVisible>
              </div>
            ))}
          </div>
          <div className="book-meeting-cta-wrapper">
            <a
              href="https://go.oncehub.com/MeetingEG"
              target="_blank"
              rel="noreferrer"
              className="book-meeting-cta"
            >
              {translation.anchorstrategy.onboardingCTA}
            </a>
          </div>
          <Separator />
          <Headline>
            <div className="headline-content">{translation.anchorstrategy.headline5}</div>
          </Headline>
          <div className="multi-hexagon-graphic">
            <img src={multiHexagonGraphic} alt="" />
          </div>
          <Separator />
          <Headline>
            <div className="headline-content">{translation.anchorstrategy.headline6}</div>
          </Headline>
          <div className="timeline">
            <img src={timeline} alt="" />
          </div>
          <Separator />
          <Headline>
            <div className="headline-content">{translation.anchorstrategy.headline7}</div>
          </Headline>
          <div className="get-to-know">
            <GetToKnow />
          </div>
        </CustomContainer>
      </div>
    </Layout>
  )
}

export default Anchorstrategy
