import Template from './Template/Template'
import hero from '../../assets/image/branches/immobilien/hero.png'

// trausted brand images
import bikini_logo from '../../assets/image/branches/immobilien/logos/bikini_logo.png'
import bnm_logo from '../../assets/image/branches/immobilien/logos/bnm_logo.png'
import hilpert_logo from '../../assets/image/branches/immobilien/logos/hilpert_logo.png'
import hilperthaus_logo from '../../assets/image/branches/immobilien/logos/hilperthaus_logo.png'
import hyprofinance_logo from '../../assets/image/branches/immobilien/logos/hyprofinance_logo.png'
import sanahotel_logo from '../../assets/image/branches/immobilien/logos/sanahotel_logo.png'

// bulletpoint images
import blackflagship_markenagentur_0006_Immo from '../../assets/image/branches/immobilien/gallery/blackflagship_markenagentur_0006_Immo.png'
import blackflagship_markenagentur_0012_immo_2 from '../../assets/image/branches/immobilien/gallery/blackflagship_markenagentur_0012_immo_2.png'
import blackflagship_markenagentur_0013_immo_1 from '../../assets/image/branches/immobilien/gallery/blackflagship_markenagentur_0013_immo_1.png'

import hexSVG1 from '../../assets/image/branches/immobilien/svgs/Immo_1.svg'
import hexSVG2 from '../../assets/image/branches/immobilien/svgs/Immo_2.svg'
import hexSVG3 from '../../assets/image/branches/immobilien/svgs/Immo_3.svg'
import hexSVG4 from '../../assets/image/branches/immobilien/svgs/Immo_4.svg'
import hexSVG5 from '../../assets/image/branches/immobilien/svgs/Immo_5.svg'

const Immobilien = () => {
  const trustedBrandImages = [
    bikini_logo,
    bnm_logo,
    hilpert_logo,
    hilperthaus_logo,
    hyprofinance_logo,
    sanahotel_logo,
  ]
  const bulletpointImages = [
    blackflagship_markenagentur_0006_Immo,
    blackflagship_markenagentur_0012_immo_2,
    blackflagship_markenagentur_0013_immo_1,
  ]

  const hexSvgs = [hexSVG1, hexSVG2, hexSVG3, hexSVG4, hexSVG5]

  return (
    <Template
      translationKeyword={'immobilien'}
      hero={hero}
      svgs={hexSvgs}
      trustedBrandImages={trustedBrandImages}
      bulletpointImages={bulletpointImages}
    />
  )
}

export default Immobilien
