import "./Hero.scss";
import Separator from "../Separator/Separator";

const Hero = ({ img }) => {
  return (
    <div className="hero" style={{ backgroundImage: `url(${img})` }}>
      <div className="separator-wrapper">
        <Separator />
      </div>
    </div>
  );
};

export default Hero;
