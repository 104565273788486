import './HexItem.scss'
import { useSelector } from 'react-redux'

const HexItem = ({ text, svg, isOdd }) => {
  const translation = useSelector((state) => state.translation)
  return (
    <img
      src={svg}
      className={`branch-hex-elem ${isOdd ? 'odd' : ''}`}
      alt={translation.project.name}
      preserveAspectRatio="xMinYMax meet"
    ></img>
  )
}
export default HexItem
