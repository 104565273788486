import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import { ToastContainer } from "react-toastify";
import Home from "./components/Home/Home";
import Contact from "./components/Contact/Contact";
import Businessnavigator from "./components/Businessnavigator/Businessnavigator";
import Blackpaper from "./components/Blackpaper/Blackpaper";
import Anchorworkshop from "./components/Anchorworkshop/Anchorworkshop";
import Anchorstrategy from "./components/Anchorstrategy/Anchorstrategy";
import Mobility from "./components/branches/Mobility";
import Finance from "./components/branches/Finance";
import Jewelry from "./components/branches/Jewelry";
import EnergyInfrastructure from "./components/branches/EnergyInfrastructure";
import Consulting from "./components/branches/Consulting";
import Immobilien from "./components/branches/Immobilien";
import Luxus from "./components/branches/Luxus";
import Industry from "./components/branches/Industry";
import FoodBeverages from "./components/branches/FoodBeverages";
import WhoWeAre from "./components/WhoWeAre/WhoWeAre";
import WhatWeDo from "./components/WhatWeDo/WhatWeDo";
import Imprint from "./components/Imprint/Imprint";
import DataProtection from "./components/DataProtection/DataProtection";

function App() {
  return (
    <div className="App">
      <Router>
        <Routes>
          <Route index element={<Home />} />
          <Route path={"contact"} element={<Contact />} />
          <Route path={"businessnavigator"} element={<Businessnavigator />} />
          <Route path={"blackpaper"} element={<Blackpaper />} />
          <Route path={"anchorworkshop"} element={<Anchorworkshop />} />
          <Route path={"anchorstrategy"} element={<Anchorstrategy />} />
          <Route path={"who-we-are"} element={<WhoWeAre />} />
          <Route path={"what-we-do"} element={<WhatWeDo />} />

          {/* branches start */}
          <Route path={"mobility"} element={<Mobility />} />
          <Route path={"finance"} element={<Finance />} />
          <Route path={"jewelry"} element={<Jewelry />} />
          <Route
            path={"energyinfrastructure"}
            element={<EnergyInfrastructure />}
          />
          <Route path={"consulting"} element={<Consulting />} />
          <Route path={"immobilien"} element={<Immobilien />} />
          <Route path={"luxus"} element={<Luxus />} />
          <Route path={"industry"} element={<Industry />} />
          <Route path={"foodbeverages"} element={<FoodBeverages />} />
          {/* branches end */}

          <Route path={"impressum"} element={<Imprint />} />
          <Route path={"datenschutz"} element={<DataProtection />} />
          <Route path={"*"} element={<Navigate replace to="/" />} />
        </Routes>
      </Router>
      <ToastContainer />
    </div>
  );
}

export default App;
