import Template from './Template/Template'
import hero from '../../assets/image/branches/consulting/hero.png'

// trausted brand images
import allianz_logo from '../../assets/image/branches/consulting/logos/allianz_logo.png'
import hilpert_logo from '../../assets/image/branches/consulting/logos/hilpert_logo.png'
import mybestconcept_logo from '../../assets/image/branches/consulting/logos/mybestconcept_logo.png'
import protax_logo from '../../assets/image/branches/consulting/logos/protax_logo.png'
import steinkellner_logo from '../../assets/image/branches/consulting/logos/steinkellner_logo.png'

// bulletpoint images
import blackflagship_markenagentur_0002_Consulting from '../../assets/image/branches/consulting/gallery/blackflagship_markenagentur_0002_Consulting.png'
import blackflagship_markenagentur_0004_Consulting from '../../assets/image/branches/consulting/gallery/blackflagship_markenagentur_0004_Consulting.png'
import blackflagship_markenagentur_0005_Consulting from '../../assets/image/branches/consulting/gallery/blackflagship_markenagentur_0005_Consulting.png'

import hexSVG2 from '../../assets/image/branches/consulting/svgs/Consulting_1.svg'
import hexSVG3 from '../../assets/image/branches/consulting/svgs/Consulting_2.svg'
import hexSVG4 from '../../assets/image/branches/consulting/svgs/Consulting_3.svg'
import hexSVG5 from '../../assets/image/branches/consulting/svgs/Consulting_4.svg'
import hexSVG1 from '../../assets/image/branches/consulting/svgs/Consulting.svg'

const Consulting = () => {
  const trustedBrandImages = [
    allianz_logo,
    hilpert_logo,
    mybestconcept_logo,
    protax_logo,
    steinkellner_logo,
  ]
  const bulletpointImages = [
    blackflagship_markenagentur_0002_Consulting,
    blackflagship_markenagentur_0004_Consulting,
    blackflagship_markenagentur_0005_Consulting,
  ]

  const hexSvgs = [hexSVG1, hexSVG2, hexSVG3, hexSVG4, hexSVG5]

  return (
    <Template
      translationKeyword={'consulting'}
      hero={hero}
      svgs={hexSvgs}
      trustedBrandImages={trustedBrandImages}
      bulletpointImages={bulletpointImages}
    />
  )
}

export default Consulting
