import "./Imprint.scss";
import Layout from "../Layout/Layout";
import Text from "./Sections/Text/Text";
import Hero from "../prebuild-sections/Hero/Hero";
import heroImage from "../../assets/image/imprint/hero.png";
import { useSelector } from "react-redux";
import MetaTags from "../prebuild-sections/MetaTags/MetaTags";

const Imprint = () => {
  const translation = useSelector((s) => s.translation);
  return (
    <div id="imprint">
      <MetaTags
        title={translation.imprint.headline}
        description={translation.imprint.headline}
        image={heroImage}
      />
      <Layout>
        <Hero img={heroImage} />
        <Text />
      </Layout>
    </div>
  );
};

export default Imprint;
