import './Businessnavigator.scss'
import Layout from '../Layout/Layout'
import heroImage from '../../assets/image/businessnavigator/hero.png'
import Hero from '../prebuild-sections/Hero/Hero'
import { useSelector } from 'react-redux'
import { useState } from 'react'
import CustomContainer from '../prebuild-sections/CustomContainer/CustomContainer'
import Headline from '../prebuild-sections/Headline/Headline'
import Separator from '../prebuild-sections/Separator/Separator'
import strategybook from '../../assets/image/businessnavigator/strategy-book.png'
// import anchortactics from '../../assets/image/businessnavigator/anchor-tactics.png'
import gutberaten from '../../assets/image/businessnavigator/gut-beraten.png'
import b2b2c from '../../assets/image/businessnavigator/b2b2c.png'
import eventtransformation from '../../assets/image/businessnavigator/event-transformation.png'
import image90minutes from '../../assets/image/businessnavigator/90-minutes.png'
import toplearning from '../../assets/image/businessnavigator/top-learning.png'
import blackpaper from '../../assets/image/businessnavigator/blackpaper.png'
import bfs_anchorstory from '../../assets/image/businessnavigator/bfs_anchorstory.jpg'
import { getUUID } from '../../utility'
import { useEffect } from 'react'
import MetaTags from '../prebuild-sections/MetaTags/MetaTags'

const Businessnavigator = () => {
  const translation = useSelector((s) => s.translation)
  const [filterTag, setFilterTag] = useState(null)
  const [filteredItems, setFilteredItems] = useState([])
  const filters = [
    {
      name: translation.businessnavigator.filters.worthreading,
      tag: 'worthreading',
    },
    {
      name: translation.businessnavigator.filters.applydirectly,
      tag: 'applydirectly',
    },
    {
      name: translation.businessnavigator.filters.consulting,
      tag: 'consulting',
    },
  ]

  useEffect(() => {
    const items = [
      {
        img: strategybook,
        headline: translation.businessnavigator.items.strategybook.headline,
        subheadline: translation.businessnavigator.items.strategybook.subheadline,
        text: translation.businessnavigator.items.strategybook.text,
        link: '/anchorstrategy',
        tags: ['consulting'],
      },
      {
        img: bfs_anchorstory,
        headline: translation.businessnavigator.items.anchorstory.headline,
        subheadline: translation.businessnavigator.items.anchorstory.subheadline,
        text: translation.businessnavigator.items.anchorstory.text,
        link: 'https://anchorstory.blackflagship.com/',
        tags: ['applydirectly'],
      },
      // {
      //   img: anchortactics,
      //   headline: translation.businessnavigator.items.anchortactics.headline,
      //   subheadline: translation.businessnavigator.items.anchortactics.subheadline,
      //   text: translation.businessnavigator.items.anchortactics.text,
      //   link: '',
      //   tags: ['consulting'],
      // },
      {
        img: gutberaten,
        headline: translation.businessnavigator.items.gutberaten.headline,
        subheadline: translation.businessnavigator.items.gutberaten.subheadline,
        text: translation.businessnavigator.items.gutberaten.text,
        link: 'https://www.copecart.com/products/eef88c9c/checkout',
        tags: ['applydirectly'],
      },
      {
        img: b2b2c,
        headline: translation.businessnavigator.items.b2b2c.headline,
        subheadline: translation.businessnavigator.items.b2b2c.subheadline,
        text: translation.businessnavigator.items.b2b2c.text,
        link: 'https://www.amazon.de/B2B-2C-Marketing-Marketing-Guidelines-anwendet/dp/B09PVYK2JS/[…]u&qid=1641985009&sprefix=emanuel+grigorescu+%2Caps%2C87&sr=8-2',
        tags: ['worthreading'],
      },
      {
        img: eventtransformation,
        headline: translation.businessnavigator.items.eventtransformation.headline,
        subheadline: translation.businessnavigator.items.eventtransformation.subheadline,
        text: translation.businessnavigator.items.eventtransformation.text,
        link: 'https://www.copecart.com/products/201080b4/checkout',
        tags: ['applydirectly'],
      },
      {
        img: image90minutes,
        headline: translation.businessnavigator.items.image90minutes.headline,
        subheadline: translation.businessnavigator.items.image90minutes.subheadline,
        text: translation.businessnavigator.items.image90minutes.text,
        link: 'https://www.copecart.com/products/1d66e74d/checkout',
        tags: ['consulting'],
      },
      {
        img: toplearning,
        headline: translation.businessnavigator.items.toplearning.headline,
        subheadline: translation.businessnavigator.items.toplearning.subheadline,
        text: translation.businessnavigator.items.toplearning.text,
        link: 'https://www.youtube.com/playlist?list=PLgcBm3AAPeVD56gO3lVosISqQl4fKGQdO',
        tags: ['applydirectly'],
      },
      {
        img: blackpaper,
        headline: translation.businessnavigator.items.blackpaper.headline,
        subheadline: translation.businessnavigator.items.blackpaper.subheadline,
        text: translation.businessnavigator.items.blackpaper.text,
        link: '/blackpaper',
        tags: ['worthreading'],
      },
    ]

    if (!filterTag) {
      setFilteredItems(items)
    } else {
      setFilteredItems(items.filter((item) => item.tags.includes(filterTag)))
    }
  }, [filterTag, translation])

  return (
    <Layout>
      <MetaTags
        title={translation.businessnavigator.title}
        description={translation.businessnavigator.headline}
        image={heroImage}
      />
      <div id="businessnavigator">
        <Hero img={heroImage} />
        <CustomContainer>
          <Headline>
            <div className="headline-content">{translation.businessnavigator.headline}</div>
          </Headline>
          <div className="filters">
            {filters.map((filter) => (
              <div className="filter" key={getUUID()} onClick={() => setFilterTag(filter.tag)}>
                {filter.name}
              </div>
            ))}
          </div>
          <div className="reset" onClick={() => setFilterTag(null)}>
            {translation.businessnavigator.filters.reset}
          </div>
          <Separator />
          <div className="items">
            {filteredItems.map((item) => (
              <div className="item" key={getUUID()}>
                <div className="img">
                  <img src={item.img} alt={item.headline} />
                </div>
                <div className="headline">{item.headline}</div>
                <div className="subheadline">{item.subheadline}</div>
                <div className="text">{item.text}</div>
                <a href={item.link} target="_blank" rel="noreferrer" className="cta">
                  {translation.businessnavigator.itemsCTA}
                </a>
              </div>
            ))}
          </div>
        </CustomContainer>
      </div>
    </Layout>
  )
}

export default Businessnavigator
