import Template from './Template/Template'
import hero from '../../assets/image/branches/industry/hero.png'

// trausted brand images
import bucinator_logo from '../../assets/image/branches/industry/logos/bucinator_logo.png'
import eppledruckfarben_logo from '../../assets/image/branches/industry/logos/eppledruckfarben_logo.png'
import fca_logo from '../../assets/image/branches/industry/logos/fca_logo.png'
import perpetuumprogress_logo from '../../assets/image/branches/industry/logos/perpetuumprogress_logo.png'
import sichert_logo from '../../assets/image/branches/industry/logos/sichert_logo.png'
import wuerth_logo from '../../assets/image/branches/industry/logos/wuerth_logo.png'

// bulletpoint images
import blackflagship_markenagentur_0008_Infrastruktur from '../../assets/image/branches/industry/gallery/blackflagship_markenagentur_0008_Infrastruktur.png'

import hexSVG1 from '../../assets/image/branches/industry/svgs/Element 45.svg'
import hexSVG2 from '../../assets/image/branches/industry/svgs/Element 44.svg'
import hexSVG3 from '../../assets/image/branches/industry/svgs/Element 43.svg'
import hexSVG4 from '../../assets/image/branches/industry/svgs/Element 42.svg'
import hexSVG5 from '../../assets/image/branches/industry/svgs/Element 41.svg'

const Industry = () => {
  const trustedBrandImages = [
    bucinator_logo,
    eppledruckfarben_logo,
    fca_logo,
    perpetuumprogress_logo,
    sichert_logo,
    wuerth_logo,
  ]
  const bulletpointImages = [blackflagship_markenagentur_0008_Infrastruktur]

  const hexSvgs = [hexSVG1, hexSVG2, hexSVG3, hexSVG4, hexSVG5]

  return (
    <Template
      translationKeyword={'industry'}
      hero={hero}
      svgs={hexSvgs}
      trustedBrandImages={trustedBrandImages}
      bulletpointImages={bulletpointImages}
    />
  )
}

export default Industry
