import hex1 from "../../../assets/image/anchorworkshop/hex1.png";
import hex2 from "../../../assets/image/anchorworkshop/hex2.png";
import hex3 from "../../../assets/image/anchorworkshop/hex3.png";
import hex4 from "../../../assets/image/anchorworkshop/hex4.png";
import hex5 from "../../../assets/image/anchorworkshop/hex5.png";
import "./HexSection.scss";

const HexSection = () => {
  return (
    <div className="anchorworkshop-hex-section">
      <div className="line">
        <div className="item">
          <img src={hex1} alt="" />
        </div>
        <div className="item">
          <img src={hex2} alt="" />
        </div>
      </div>
      <div className="line">
        <div className="item">
          <img src={hex3} alt="" />
        </div>
        <div className="item">
          <img src={hex4} alt="" />
        </div>
        <div className="item">
          <img src={hex5} alt="" />
        </div>
      </div>
    </div>
  );
};

export default HexSection;
