import { useSelector } from 'react-redux'
import './HexItem.scss'

const HexItem = ({ text, isOdd, svg }) => {
  const translation = useSelector((state) => state.translation)
  return (
    <img src={svg} className={`hexElem ${isOdd ? 'odd' : ''}`} alt={translation.project.name}></img>
  )
}

export default HexItem
